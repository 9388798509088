progress, sub, sup {
  vertical-align: baseline; }

button, hr, input {
  overflow: visible; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

figcaption, menu, article, aside, details, figure, footer, header, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active, a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

button, input, optgroup, select, textarea {
  font: inherit;
  margin: 0; }

optgroup {
  font-weight: 700; }

button, select {
  text-transform: none; }

[type=submit], [type=reset], button, html [type=button] {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
  outline: ButtonText dotted 1px; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

/* FiraSansRegular
@font-face { 
	font-family: 'FiraSansRegular'; 
	src: url('../fonts/FiraSansRegular/FiraSansRegular.woff') format('woff'), 
		  url('../fonts/FiraSansRegular/FiraSansRegular.ttf')  format('truetype');
	font-weight: normal; 
	font-style: normal; 
}

FiraSansLight
@font-face { 
	font-family: 'FiraSansLight'; 
	src: url('../fonts/FiraSansLight/FiraSansLight.woff') format('woff'), 
		  url('../fonts/FiraSansLight/FiraSansLight.ttf')  format('truetype');
	font-weight: normal; 
	font-style: normal; 
}

FiraSansMedium
@font-face { 
	font-family: 'FiraSansMedium'; 
	src: url('../fonts/FiraSansMedium/FiraSansMedium.woff') format('woff'), 
		  url('../fonts/FiraSansMedium/FiraSansMedium.ttf')  format('truetype');
	font-weight: normal; 
	font-style: normal; 
}

FiraSansBold
@font-face { 
	font-family: 'FiraSansBold'; 
	src: url('../fonts/FiraSansBold/FiraSansBold.woff') format('woff'), 
		  url('../fonts/FiraSansBold/FiraSansBold.ttf')  format('truetype');
	font-weight: normal; 
	font-style: normal; 
}

FiraSansMediumItalic
@font-face { 
	font-family: 'FiraSansMediumItalic'; 
	src: url('../fonts/FiraSansMediumItalic/FiraSansMediumItalic.woff') format('woff'), 
		  url('../fonts/FiraSansMediumItalic/FiraSansMediumItalic.ttf')  format('truetype');
	font-weight: normal; 
	font-style: normal; 
}
 */
* {
  box-sizing: border-box; }

h1,
h2,
h3,
h4,
p,
ul,
figure,
strong,
blockquote {
  margin: 0;
  padding: 0; }

h1,
h2,
h3 {
  font-weight: normal; }

html {
  overflow-x: hidden;
  font-size: 16px; }

body {
  width: 100%;
  overflow-x: hidden;
  color: #5a4a42;
  font: 1rem 'Fira Sans';
  font-weight: 300;
  background: #FFFFFF;
  line-height: 1.4; }

a {
  color: inherit;
  cursor: pointer;
  outline: none; }
  a:hover {
    transition: all 0.4s;
    text-decoration: none;
    color: inherit; }
  a:focus {
    text-decoration: none;
    outline: none;
    color: inherit; }

img {
  max-width: 100%; }

button {
  outline: none;
  border: none; }

.no-wrap {
  white-space: nowrap; }

.icon {
  display: inline-block; }

.container {
  max-width: 940px; }

section {
  padding: 70px 0; }

.section-header {
  font: 700 3.125rem/1.1 'Fira Sans';
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #5a4a42; }
  .section-header .subheader {
    display: block;
    font: 300 2.19rem/1.2 'Fira Sans';
    letter-spacing: 0;
    text-transform: none; }

.btn {
  display: inline-block;
  padding: 20px;
  width: 300px;
  color: #28323C;
  background: #FFBA00;
  border: none;
  outline: none;
  border-bottom: 1px solid #FF9000;
  border-radius: 100px;
  text-shadow: 0 1px 0 #FFD77D;
  text-align: center;
  font: 700 1.5rem/1 'Fira Sans'; }
  .btn:hover {
    background: #FFD666; }

.form {
  position: relative;
  padding: 40px 20px 0;
  background: #FFFFFF;
  border: 1px solid #888888;
  text-align: center;
  color: #4b4453; }
  .form-wrapper {
    position: relative;
    z-index: 10;
    padding: 70px 35px 35px;
    width: 100%;
    max-width: 435px;
    background: #FFFFFF; }
  .form-title {
    margin-bottom: 25px;
    font: 500 1.44rem/1.2 'Fira Sans'; }
    .form-title span {
      display: block;
      text-transform: uppercase;
      margin-bottom: 10px; }
  .form .input-wrapper {
    position: relative;
    margin: 0 auto 15px;
    width: 100%;
    max-width: 250px; }
  .form .tooltip {
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer; }
    .form .tooltip-icon {
      display: block;
      background-image: url("../img/anyplace_sprite.png");
      width: 23px;
      height: 23px;
      background-position: -329px -5px; }
      .form .tooltip-icon:hover + .tooltip-text {
        display: block; }
    .form .tooltip-text {
      position: absolute;
      top: -56px;
      right: 27px;
      display: none;
      width: 250px;
      padding: 10px 20px;
      color: #393538;
      background: #ffba00;
      border: 1px solid #FFAD33;
      border-radius: 10px 10px 0 10px;
      font-size: 0.85rem;
      box-shadow: -5px -5px 2px rgba(0, 0, 0, 0.1); }
  .form .confirm {
    display: flex;
    text-align: left;
    align-items: flex-start;
    max-width: 330px;
    margin-bottom: 20px; }
    .form .confirm label {
      margin-left: 8px;
      font: 400 0.875rem/1.1 'Fira Sans';
      cursor: pointer; }
    .form .confirm input[type="checkbox"] {
      flex-shrink: 0;
      width: 15px;
      height: 15px;
      outline: none; }
    .form .confirm .error ~ label {
      color: #FF1100; }
    .form .confirm a {
      display: inline-block;
      line-height: 1;
      border-bottom: 1px solid  #373736; }
      .form .confirm a:hover {
        border-bottom-style: dashed; }
  .form input[type="text"],
  .form input[type="tel"],
  .form input[type="email"] {
    width: 100%;
    padding: 10px;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: 2px solid #a3a0a7; }
    .form input[type="text"]:focus,
    .form input[type="tel"]:focus,
    .form input[type="email"]:focus {
      border-bottom-color: #000000; }
    .form input[type="text"].error,
    .form input[type="tel"].error,
    .form input[type="email"].error {
      border-bottom-color: #FF1100; }
  .form select {
    width: 100%;
    padding: 10px;
    text-align: center;
    outline: none;
    border: 2px solid #a3a0a7;
    border-radius: 5px;
    background: #FFFFFF; }
  .form input[type="submit"] {
    margin: 20px 0;
    padding: 15px 20px;
    outline: none;
    white-space: normal;
    font-size: 1.25rem; }
  .form .userfile {
    display: block;
    position: relative;
    width: 100%;
    max-width: 330px;
    margin: 0 auto 10px;
    cursor: pointer;
    border: 0;
    height: 40px;
    border-radius: 5px;
    outline: 0;
    font-size: 0.875rem; }
    .form .userfile::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;
      content: '';
      background-image: url("../img/anyplace_sprite.png");
      width: 22px;
      height: 23px;
      background-position: -297px -5px; }
    .form .userfile::after {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      display: block;
      content: attr(data-text);
      overflow: hidden;
      text-decoration: underline;
      color: #4b4453;
      line-height: 40px;
      border-radius: 5px;
      transition: 200ms all ease;
      background-color: #FFFFFF; }
  .form label.error {
    display: none !important; }
  .form .bottom-text {
    margin: 0 auto -15px;
    width: 220px;
    background: #FFFFFF;
    font: 400 0.85rem/1.1 'Fira Sans'; }
    .form .bottom-text .rounded {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      font-weight: 700;
      color: #4b4453;
      background: #ffecba;
      border-radius: 50%; }
  .form::before {
    position: absolute;
    top: -40px;
    left: calc(50% - 124px);
    content: '';
    width: 247px;
    height: 102px;
    background: url("../img/catalog_form-bg.png"); }

/*______________________________________________________
________________________ header ______________________*/
header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
  header .logo {
    flex-shrink: 0; }
    header .logo-text {
      margin: -10px 0 0 75px;
      line-height: 1;
      font-size: 0.875rem; }
  header .phone {
    position: relative;
    font: 700 1rem/1.1 'Fira Sans'; }
    header .phone span {
      font-size: 1.81rem;
      color: #E61F31; }
    header .phone::before {
      position: absolute;
      top: -1px;
      left: -28px;
      content: '';
      background-image: url("../img/anyplace_sprite.png");
      width: 14px;
      height: 15px;
      background-position: -181px -5px; }
    header .phone-wrapper {
      flex-shrink: 0;
      text-align: right;
      font-size: 0.875rem; }
  header .call-back {
    display: inline-block;
    line-height: 1;
    border-bottom: 1px dashed #373736; }
  header .delivery {
    padding: 15px 20px;
    border: 1px dashed #ded7d1;
    border-radius: 8px;
    line-height: 1.2; }
    header .delivery span {
      display: inline-block;
      border-bottom: 1px dashed #4b4453; }

/*______________________________________________________
________________________ base __________________________
_____________________ 1st screen  ____________________*/
.base {
  padding-top: 20px;
  background: url(../img/base_bg.jpg) no-repeat center;
  background-size: cover;
  color: #373736; }
  .base .content {
    display: flex;
    align-items: flex-end;
    margin-top: 210px; }
  .base .order {
    flex: 1;
    color: #4b4453; }
    .base .order-kithen {
      color: #4b4453;
      text-transform: uppercase;
      font: 300 1.81rem/0.8 'Fira Sans'; }
      .base .order-kithen span {
        display: block;
        color: #e61d31;
        font: 700 4rem/1 'Fira Sans';
        text-shadow: 0 -2px 1px #FFFFFF; }
    .base .order-economy {
      margin-top: 40px;
      text-transform: uppercase;
      font: 300 1.81rem/1 'Fira Sans'; }
      .base .order-economy span {
        display: block;
        color: #ff7800;
        font: 700 2.19rem/1 'Fira Sans'; }
    .base .order-guarantee {
      font-size: 1.25rem;
      line-height: 1.2;
      margin-top: 25px; }
  .base .btn {
    position: relative;
    padding: 17px;
    width: 382px;
    font: 700 1rem/1.2 'Fira Sans';
    text-transform: uppercase; }
    .base .btn::before {
      position: absolute;
      bottom: -78px;
      left: 0;
      content: '';
      width: 382px;
      height: 78px;
      background: url("../img/base_btn-shadow.png"); }
    .base .btn::after {
      position: absolute;
      bottom: -68px;
      right: -74px;
      content: '';
      background-image: url("../img/anyplace_sprite.png");
      width: 142px;
      height: 152px;
      background-position: -29px -5px; }
    .base .btn-wrapper {
      margin-bottom: 15px;
      flex: 1;
      text-align: right; }

/*______________________________________________________
_____________________ our-works ________________________
_____________________ 2nd screen  ____________________*/
.our-works {
  padding-bottom: 100px;
  background: url("../img/united_bg.png");
  color: #564b44;
  /*	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 
	}*/ }
  .our-works .container {
    max-width: 1090px;
    width: 1090px; }
  .our-works .section-header {
    margin-left: 150px; }
  .our-works .tabs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 45px;
    list-style: none;
    overflow: hidden; }
    .our-works .tabs li a {
      display: block;
      padding: 20px 40px;
      color: #564b44;
      text-transform: uppercase;
      font: 500 1rem/1 'Fira Sans';
      text-decoration: none; }
    .our-works .tabs li.active {
      position: relative;
      border-top: 2px solid #cfbcb4;
      border-left: 2px solid #cfbcb4;
      border-right: 2px solid #cfbcb4;
      border-radius: 10px 10px 0 0; }
      .our-works .tabs li.active::before, .our-works .tabs li.active::after {
        position: absolute;
        bottom: 0;
        content: '';
        width: 1000px;
        border-bottom: 2px solid #cfbcb4; }
      .our-works .tabs li.active::before {
        left: 100%; }
      .our-works .tabs li.active::after {
        right: 100%; }
    .our-works .tabs li:not(.active) a:hover {
      color: #999999; }
  .our-works .tab-content {
    position: relative;
    padding: 30px;
    border-left: 2px solid #cfbcb4;
    border-right: 2px solid #cfbcb4; }
  .our-works .tab-wrapper {
    display: flex;
    margin-bottom: 30px; }
  .our-works .tab-gallery {
    flex-shrink: 0;
    width: 555px;
    max-height: 500px; }
    .our-works .tab-gallery .gallery {
      width: 100%; }
      .our-works .tab-gallery .gallery img {
        margin: 0 auto;
        max-width: 600px; }
      .our-works .tab-gallery .gallery .owl-controls {
        position: absolute;
        top: 20px;
        left: calc(100% + 50px); }
  .our-works .tab-description {
    padding-top: 110px;
    margin-left: 50px; }
    .our-works .tab-description ul {
      margin: 20px 0;
      list-style: none;
      font-size: 1.25rem; }
    .our-works .tab-description li {
      position: relative;
      margin-bottom: 12px;
      padding-left: 40px;
      color: #5a4a42; }
      .our-works .tab-description li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #ffd674;
        border-radius: 100%; }
      .our-works .tab-description li::after {
        position: absolute;
        top: 7px;
        left: 6px;
        content: '';
        background-image: url("../img/anyplace_sprite.png");
        width: 14px;
        height: 11px;
        background-position: -5px -5px; }
  .our-works .btn {
    position: relative;
    margin: 0 70px; }
    .our-works .btn-wrapper {
      margin-top: -34px;
      position: relative;
      overflow: hidden;
      text-align: right; }
    .our-works .btn::before, .our-works .btn::after {
      position: absolute;
      top: 50%;
      content: '';
      width: 1000px;
      border-bottom: 2px solid #cfbcb4; }
    .our-works .btn::before {
      left: calc(100% + 20px); }
    .our-works .btn::after {
      right: calc(100% + 20px); }

/*______________________________________________________
_______________________ catalog ________________________
_____________________ 3rd screen  ____________________*/
.catalog {
  position: relative;
  background: url("../img/catalog_bg.jpg") no-repeat center;
  background-size: cover; }
  .catalog .magazine {
    display: none; }
    .catalog .magazine-wrapper {
      display: none; }
  .catalog .section-header {
    color: #FFECBA; }
    .catalog .section-header .subheader {
      color: #D7D7D7; }
  .catalog .form {
    padding: 40px 10px 0; }
    .catalog .form-flex-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 80px; }
    .catalog .form input[type="submit"] {
      padding: 20px 10px; }

/*______________________________________________________
________________________ enjoy _________________________
_____________________ 4th screen  ____________________*/
.enjoy {
  position: relative;
  padding-top: 100px;
  background: url("../img/enjoy_bg.jpg") no-repeat center;
  background-size: cover; }
  .enjoy .section-header {
    color: #99947f; }
    .enjoy .section-header .subheader {
      color: #4b4453; }
  .enjoy .fittings {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px; }
    .enjoy .fittings-item {
      position: relative;
      overflow: hidden;
      width: 300px;
      height: 300px;
      text-align: center; }
      .enjoy .fittings-item:nth-of-type(1) .fittings-item-info, .enjoy .fittings-item:nth-of-type(2) .fittings-item-info {
        bottom: -149px; }
      .enjoy .fittings-item:nth-of-type(8) {
        width: 600px; }
      .enjoy .fittings-item:hover .fittings-item-info {
        bottom: 0; }
      .enjoy .fittings-item img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .enjoy .fittings-item-info {
        position: absolute;
        bottom: -127px;
        width: 100%;
        background: rgba(255, 255, 255, 0.85);
        transition: all 0.5s; }
        .enjoy .fittings-item-info .visible-part {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          height: 90px;
          font: 500 1.25rem 'Fira Sans'; }
        .enjoy .fittings-item-info .hidden-part {
          margin: 5px 15px 20px;
          padding: 15px 10px;
          color: #4a4653;
          border: 2px solid #96937f; }

/*______________________________________________________
_______________________ factory ________________________
_____________________ 5th screen  ____________________*/
.factory {
  background: url("../img/factory_bg.jpg") no-repeat center;
  background-size: cover; }
  .factory .section-header {
    color: #FFECBA; }
    .factory .section-header .subheader {
      color: #D7D7D7; }
  .factory .video {
    width: 600px;
    height: 271px; }
    .factory .video iframe {
      width: 100%;
      height: 100%;
      border: none; }
    .factory .video-wrapper {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
      padding: 15px;
      height: 120px;
      border: 2px solid #9e9d9e; }
      .factory .video-wrapper p {
        font: 500 1.25rem 'Fira Sans';
        color: #FFFFFF;
        text-align: right; }
  .factory .quote {
    color: #FFFFFF;
    margin-left: 25px; }
    .factory .quote-wrapper {
      display: flex;
      align-items: center;
      margin-top: 240px; }
    .factory .quote-title {
      display: block;
      color: #ffecba;
      font: 500 italic 2.1rem 'Fira Sans'; }
    .factory .quote-text {
      margin: 30px 0 20px;
      line-height: 1.2; }
    .factory .quote-author {
      display: flex;
      align-items: center;
      font-style: italic; }
      .factory .quote-author .icon-signature {
        margin-left: 30px; }
  .factory .advantages {
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px; }
    .factory .advantages-item {
      margin: 0 1%;
      padding: 10px;
      width: 31.3%;
      min-height: 240px;
      background: #ffecba;
      text-align: center; }
      .factory .advantages-item-title {
        display: block;
        margin: 20px 0;
        font: 500 1.375rem/1.2 'Fira Sans'; }
  .factory .icon {
    display: block;
    background-image: url("../img/factory_sprite.png"); }
    .factory .icon1 {
      width: 52px;
      height: 46px;
      background-position: -5px -5px; }
    .factory .icon2 {
      width: 50px;
      height: 49px;
      background-position: -67px -5px; }
    .factory .icon3 {
      width: 50px;
      height: 53px;
      background-position: -127px -5px; }
    .factory .icon-signature {
      width: 113px;
      height: 62px;
      background-position: -187px -5px; }
    .factory .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -60px auto 0;
      width: 96px;
      height: 96px;
      background: #393538;
      border: 2px solid #FFECBA;
      border-radius: 50%; }

.united-bg {
  background: url("../img/united_bg.png"); }

/*______________________________________________________
___________________ perfect-kitchen ____________________
_____________________ 6th screen  ____________________*/
.perfect-kitchen {
  position: relative; }
  .perfect-kitchen .section-header .subheader span {
    display: inline-block;
    border-bottom: 1px dashed #373736; }
  .perfect-kitchen .girl-wrapper {
    position: absolute;
    z-index: 1;
    top: 270px;
    width: 550px;
    right: calc(50% + 20px); }
    .perfect-kitchen .girl-wrapper::before {
      position: absolute;
      z-index: -1;
      top: 98px;
      right: -85px;
      content: '';
      width: 713px;
      height: 713px;
      background: url("../img/perfect-kitchen_circle.png"); }
  .perfect-kitchen .steps {
    position: relative;
    z-index: 10;
    margin: 220px 0 0 390px; }
    .perfect-kitchen .steps-item {
      display: flex;
      align-items: center;
      margin-bottom: 45px; }
      .perfect-kitchen .steps-item:nth-of-type(2) {
        margin-left: 60px; }
      .perfect-kitchen .steps-item:nth-of-type(3) {
        margin-left: 95px; }
      .perfect-kitchen .steps-item:nth-of-type(4) {
        margin-left: 110px; }
      .perfect-kitchen .steps-item:nth-of-type(5) {
        margin-left: 95px; }
      .perfect-kitchen .steps-item:nth-of-type(6) {
        margin: 50px 0 0 15px; }
      .perfect-kitchen .steps-item .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        background: #ffd674;
        border-radius: 50%;
        flex-shrink: 0; }
      .perfect-kitchen .steps-item .icon-kitchen {
        display: block;
        width: 51px;
        height: 54px;
        background: url("../img/perfect-kitchen_kitchen-icon.png"); }
      .perfect-kitchen .steps-item-num {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        color: #5a4a42;
        background: #ffd674;
        font: 700 1.25rem 'Fira Sans';
        border-radius: 50%; }
      .perfect-kitchen .steps-item-text {
        margin-left: 20px;
        font-weight: 400;
        line-height: 1.2;
        color: #373736; }
        .perfect-kitchen .steps-item-text span {
          font-weight: 700; }
        .perfect-kitchen .steps-item-text a {
          color: #5e7fc1;
          text-decoration: underline; }
          .perfect-kitchen .steps-item-text a:hover {
            color: #ffb700; }

/*______________________________________________________
_______________________ our goal _______________________
_____________________ 7th screen  ____________________*/
.our-goal {
  position: relative;
  margin-bottom: -100px;
  padding: 0;
  height: 800px; }
  .our-goal p {
    position: relative;
    z-index: 10;
    display: inline-block;
    padding: 20px 50px;
    color: #373736;
    background: #FFFFFF;
    border: 2px dashed #938883;
    border-radius: 8px;
    font: 300 1.375rem/1.2 'Fira Sans'; }

/*______________________________________________________
_____________________ testimonials _____________________
_____________________ 8th screen  ____________________*/
.testimonials {
  padding-top: 0; }
  .testimonials .container {
    max-width: 1090px;
    width: 1090px; }
  .testimonials-carousel {
    margin-top: 60px; }
  .testimonials .owl-carousel .owl-item img {
    width: auto; }
  .testimonials .owl-controls {
    position: absolute;
    top: 0;
    right: 100px; }
  .testimonials .owl-stage-outer {
    padding-top: 100px; }
  .testimonials-item {
    display: flex;
    height: 470px; }
    .testimonials-item-photo {
      width: 500px;
      flex-shrink: 0;
      height: 100%; }
      .testimonials-item-photo img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover; }
    .testimonials-item-info {
      position: relative;
      padding: 0 30px 0 50px;
      border: 2px solid #e0dfdf;
      background: #fff; }
    .testimonials-item-author {
      display: flex;
      align-items: flex-end;
      margin-top: -70px; }
      .testimonials-item-author img {
        display: block;
        width: 140px;
        height: 140px;
        border-radius: 100%; }
      .testimonials-item-author-name {
        margin: 0 0 10px 30px;
        font: 700 1.25rem 'Fira Sans'; }
    .testimonials-item-title {
      margin: 25px 0 20px;
      font: 700 1.25rem/1.1 'Fira Sans';
      letter-spacing: 2px;
      text-transform: uppercase; }
    .testimonials-item-text {
      height: 230px; }
      .testimonials-item-text p {
        font: 400 0.875rem/1.3 'Fira Sans';
        margin-bottom: 0.8rem; }

/*______________________________________________________
________________________ faq ___________________________
_____________________ 9th screen  ____________________*/
.faq {
  position: relative;
  background: url("../img/faq_bg.png"); }
  .faq .section-header {
    color: #7894CD; }
  .faq .kitchen {
    display: none; }
  .faq .questions {
    position: relative;
    margin: 90px 0 0 350px;
    min-height: 630px; }
    .faq .questions::before {
      position: absolute;
      top: -30px;
      right: calc(100% + 100px);
      content: '';
      width: 746px;
      height: 608px;
      background: url("../img/faq_kitchen.png"); }
    .faq .questions-item {
      position: relative;
      margin-bottom: 30px;
      background: transparent;
      border: none;
      box-shadow: none; }
      .faq .questions-item::before {
        position: absolute;
        top: -10px;
        left: -55px;
        display: flex;
        align-items: center;
        justify-content: center;
        content: '?';
        width: 40px;
        height: 40px;
        text-align: center;
        color: #5a4a42;
        background: #adc6ee;
        border-radius: 100%;
        font: 700 1.875rem/1 'Fira Sans'; }
      .faq .questions-item-trigger {
        color: #373736;
        font-weight: 500;
        cursor: pointer; }
        .faq .questions-item-trigger:hover {
          color: #ffb700; }
          .faq .questions-item-trigger:hover span {
            border-color: #ffb700; }
        .faq .questions-item-trigger span {
          display: inline-block;
          border-bottom: 1px dashed #373736; }
      .faq .questions-item-answer {
        margin: 15px 0;
        color: #7b7b7b;
        font: 400 0.875rem 'Fira Sans'; }
        .faq .questions-item-answer a {
          display: inline-block;
          color: #5e7fc1;
          text-decoration: underline; }
        .faq .questions-item-answer p {
          margin-bottom: 0.75rem; }
      .faq .questions-item.active .questions-item-answer {
        display: block; }
  .faq .no-answer {
    margin: 50px 0 10px;
    color: #7894CD;
    font: 700 3.125rem/1.1 'Fira Sans';
    text-transform: uppercase;
    letter-spacing: 3px; }
  .faq .ask-now {
    display: inline-block;
    font: 2.19rem/1.2 'Fira Sans';
    border-bottom: 1px dashed #4b4453; }
    .faq .ask-now:hover {
      color: #ffb700;
      border-color: #ffb700; }

/*______________________________________________________
____________________ design-variants ___________________
_____________________ 10th screen  ___________________*/
.design-variants {
  background: url("../img/design-variants_bg.jpg") no-repeat center;
  background-size: cover; }
  .design-variants .section-header {
    color: #FFECBA; }
    .design-variants .section-header .subheader {
      color: #D7D7D7; }
  .design-variants .additional {
    display: flex;
    justify-content: space-between; }
    .design-variants .additional-title {
      margin: 60px 0 65px;
      color: #ffecba;
      font-weight: 500; }
    .design-variants .additional-item {
      margin-bottom: 70px;
      padding: 10px;
      width: 24%;
      min-height: 135px;
      background: #ffecba;
      text-align: center; }
      .design-variants .additional-item-text {
        margin-top: 10px;
        line-height: 1.2; }
  .design-variants .icon {
    display: block;
    background-image: url("../img/design-variants_sprite.png"); }
    .design-variants .icon1 {
      width: 34px;
      height: 34px;
      background-position: -5px -5px; }
    .design-variants .icon2 {
      width: 29px;
      height: 35px;
      background-position: -49px -5px; }
    .design-variants .icon3 {
      width: 42px;
      height: 31px;
      background-position: -88px -5px; }
    .design-variants .icon4 {
      width: 36px;
      height: 36px;
      background-position: -140px -5px; }
    .design-variants .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -58px auto 0;
      width: 96px;
      height: 96px;
      background: #393538;
      border: 2px solid #FFECBA;
      border-radius: 50%; }
  .design-variants .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .design-variants .tab-content {
    position: relative;
    max-width: 435px;
    height: 400px;
    padding: 25px;
    margin: 0 auto;
    border-top: 2px solid #cfbcb4;
    border-left: 2px solid #cfbcb4;
    border-right: 2px solid #cfbcb4; }
  .design-variants .tab-pane {
    height: 100%; }
    .design-variants .tab-pane img {
      height: 100%;
      object-fit: cover; }
  .design-variants .samples-title {
    margin-bottom: 20px;
    color: #ffecba;
    font-weight: 500; }
  .design-variants .tabs {
    display: flex;
    list-style: none;
    overflow: hidden; }
    .design-variants .tabs li {
      flex: 1; }
      .design-variants .tabs li a {
        display: block;
        padding: 10px;
        width: 100%;
        color: #a8a7a8;
        text-transform: uppercase;
        font: 500 1.125rem/1 'Fira Sans';
        text-decoration: none;
        text-align: center; }
      .design-variants .tabs li.active {
        position: relative;
        border-radius: 0 0 10px 10px;
        border-left: 2px solid #cfbcb4;
        border-right: 2px solid #cfbcb4;
        border-bottom: 2px solid #cfbcb4;
        border-radius: 0 0 10px 10px; }
        .design-variants .tabs li.active::before, .design-variants .tabs li.active::after {
          position: absolute;
          top: 0;
          content: '';
          width: 500px;
          border-bottom: 2px solid #cfbcb4; }
        .design-variants .tabs li.active::before {
          left: 100%; }
        .design-variants .tabs li.active::after {
          right: 100%; }
      .design-variants .tabs li:not(.active) a:hover {
        color: #888888; }
  .design-variants .form {
    padding: 20px 10px 0; }
    .design-variants .form-title {
      margin-bottom: 15px; }
    .design-variants .form input[type="submit"] {
      margin-top: 0;
      min-height: 70px; }
    .design-variants .form .bottom-text {
      width: 260px; }

/*______________________________________________________
_______________________ closet _________________________
_____________________ 11th screen  ___________________*/
.closet {
  padding: 70px 0 150px;
  background: url("../img/united_bg.png");
  color: #564b44; }
  .closet .container {
    max-width: 1090px;
    width: 1090px; }
  .closet .btn {
    position: absolute;
    width: 340px;
    right: 100px;
    bottom: -37px; }
  .closet .tabs {
    display: flex;
    margin-top: 60px;
    padding-left: 10%;
    list-style: none;
    overflow: hidden; }
    .closet .tabs li.active {
      position: relative;
      border-radius: 10px 10px 0 0;
      border-top: 2px solid #cfbcb4;
      border-left: 2px solid #cfbcb4;
      border-right: 2px solid #cfbcb4;
      border-radius: 10px 10px 0 0; }
      .closet .tabs li.active::before, .closet .tabs li.active::after {
        position: absolute;
        bottom: 0;
        content: '';
        width: 1000px;
        border-bottom: 2px solid #cfbcb4; }
      .closet .tabs li.active::before {
        left: 100%; }
      .closet .tabs li.active::after {
        right: 100%; }
    .closet .tabs li:not(.active) a:hover {
      color: #999999; }
    .closet .tabs li a {
      display: block;
      padding: 20px 40px;
      color: #564b44;
      text-transform: uppercase;
      font: 500 1.125rem/1 'Fira Sans';
      text-decoration: none; }
  .closet .tab-content {
    position: relative;
    padding: 30px;
    border-left: 2px solid #cfbcb4;
    border-right: 2px solid #cfbcb4;
    border-bottom: 2px solid #cfbcb4; }
  .closet .tab-wrapper {
    display: flex;
    margin-bottom: 30px; }
  .closet .tab-gallery {
    max-height: 450px;
    flex-shrink: 0;
    width: 555px; }
    .closet .tab-gallery .gallery {
      width: 100%; }
      .closet .tab-gallery .gallery img {
        max-height: 450px;
        margin: 0 auto;
        max-width: 600px; }
      .closet .tab-gallery .gallery .owl-controls {
        position: absolute;
        top: 20px;
        left: calc(100% + 50px); }
  .closet .tab-description {
    margin-left: 50px;
    padding-top: 120px; }
    .closet .tab-description p {
      margin-bottom: 25px;
      font: 500 1.125rem 'Fira Sans'; }
    .closet .tab-description ul {
      list-style: none;
      font-size: 1.125rem; }
    .closet .tab-description li {
      position: relative;
      margin-bottom: 12px;
      padding-left: 40px; }
      .closet .tab-description li::before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 25px;
        height: 25px;
        background-color: #ffd674;
        border-radius: 100%; }
      .closet .tab-description li::after {
        position: absolute;
        top: 7px;
        left: 5px;
        content: '';
        background-image: url("../img/anyplace_sprite.png");
        width: 14px;
        height: 11px;
        background-position: -5px -5px; }
  .closet .bottom-part .interior {
    margin: 80px 0 0 80px;
    font: 700 1.875rem/1.2 'Fira Sans'; }
  .closet .bottom-part .details {
    margin: 10px 0 40px 80px;
    display: flex;
    align-items: center; }
    .closet .bottom-part .details .icon-details {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      background: url("../img/closet_icon.png"); }
    .closet .bottom-part .details p {
      margin-left: 15px;
      font-weight: 300;
      line-height: 1.2; }
  .closet .bottom-part .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .closet .bottom-part .card {
    position: relative;
    overflow: hidden;
    width: 300px;
    height: 300px;
    text-align: center; }
    .closet .bottom-part .card-info {
      position: absolute;
      left: 0;
      bottom: -110px;
      width: 100%;
      background: rgba(255, 255, 255, 0.85);
      transition: all 0.5s; }
      .closet .bottom-part .card-info .visible-part {
        padding: 12px;
        font: 500 0.93rem 'Fira Sans'; }
      .closet .bottom-part .card-info .hidden-part {
        margin: 5px 15px 20px;
        padding: 15px 10px;
        font: 300 0.875rem 'Fira Sans';
        color: #4a4653;
        border: 2px solid #96937f; }
    .closet .bottom-part .card:nth-of-type(3) .card-info {
      bottom: -127px; }
    .closet .bottom-part .card:hover .card-info {
      bottom: 0; }

/*______________________________________________________
_________________________ map __________________________
_____________________ 12th screen  ___________________*/
.map {
  position: relative;
  padding: 0;
  height: 850px; }
  .map .container {
    position: relative; }
  .map #map {
    width: 100%;
    height: 100%; }
  .map .addresses {
    display: flex; }
    .map .addresses-back {
      position: absolute;
      max-width: 100%;
      z-index: 10;
      top: -75px;
      left: 0;
      background: #ffecba;
      padding: 73px 25px 25px; }
    .map .addresses-wrapper {
      position: relative;
      margin: 0 auto;
      padding: 10px;
      width: 100%;
      border: 2px solid #bfae82; }
      .map .addresses-wrapper::before {
        position: absolute;
        top: -54px;
        right: 25px;
        content: '';
        background: url("../img/map_addresses-top.png");
        width: 247px;
        height: 113px; }
    .map .addresses-title {
      margin: 10px 0 20px;
      color: #5a4a42;
      font: 700 1.25rem/1.2 'Fira Sans';
      text-align: center; }
    .map .addresses-item {
      padding: 0 3px;
      width: 14.28%;
      font-size: 12px;
      border-right: 2px solid #b9a87e;
      text-align: center; }
      .map .addresses-item:last-of-type {
        border: none; }
      .map .addresses-item-location {
        min-height: 96px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .map .addresses-item-phones {
        margin: 10px 0;
        font-weight: 700; }
        .map .addresses-item-phones a {
          display: block; }
      .map .addresses-item-mail {
        margin-top: 10px; }
      .map .addresses-item-timetable {
        margin-top: 12px; }
  .map .carousel-addresses-item {
    padding: 0 3px;
    width: 100%;
    font-size: 12px;
    border-right: 2px solid #b9a87e;
    text-align: center; }
    .map .carousel-addresses-item:last-of-type {
      border: none; }
    .map .carousel-addresses-item .addresses-item-location {
      min-height: auto;
      display: flex;
      align-items: center;
      justify-content: center; }
    .map .carousel-addresses-item .addresses-item-phones {
      margin: 10px 0;
      font-weight: 700; }
      .map .carousel-addresses-item .addresses-item-phones a {
        display: block; }
    .map .carousel-addresses-item .addresses-item-mail {
      margin-top: 10px; }
    .map .carousel-addresses-item .addresses-item-timetable {
      margin-top: 12px; }
  .map .owl-controls .owl-nav {
    margin: 30px 0 10px;
    justify-content: center; }

/*______________________________________________________
________________________ footer ______________________*/
footer {
  padding: 30px 0;
  color: #373736;
  font-size: 0.875rem; }
  footer .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  footer .logo {
    flex-shrink: 0; }
    footer .logo-text {
      margin: -10px 0 0 75px;
      line-height: 1; }
  footer .phone {
    position: relative;
    font: 700 1.81rem/1.2 'Fira Sans';
    color: #E61F31; }
    footer .phone::before {
      position: absolute;
      top: 13px;
      left: -22px;
      content: '';
      width: 14px;
      height: 15px;
      background-image: url("../img/anyplace_sprite.png");
      width: 14px;
      height: 15px;
      background-position: -181px -5px; }
    footer .phone-wrapper {
      text-align: center;
      flex-shrink: 0; }
  footer .call-back {
    display: inline-block;
    line-height: 1;
    border-bottom: 1px dashed #373736; }
  footer .developer p {
    margin-bottom: 10px; }
  footer .developer .diving-marketing {
    display: inline-block;
    width: 121px;
    height: 39px;
    background-image: url("../img/footer_logo-dm.png");
    background-position: -5px -5px;
    transition: none; }
    footer .developer .diving-marketing:hover {
      background-position-x: -136px; }
  footer .policy {
    margin-top: 30px;
    text-align: center; }
    footer .policy a {
      display: inline-block;
      margin: 0 10px;
      line-height: 1;
      border-bottom: 1px solid  #373736; }
      footer .policy a:hover {
        border-bottom-style: dashed; }

#modal-stock-miniature {
  position: fixed;
  z-index: 1000;
  display: none;
  bottom: 100px;
  right: 3%;
  border-radius: 50px;
  background: #424a4d; }
  #modal-stock-miniature #modal-stock-miniature-btn {
    text-transform: uppercase;
    padding: 19px 150px 14px 50px;
    cursor: pointer; }
  #modal-stock-miniature p {
    color: #FFBA00;
    text-shadow: 0 -2px 1px #FFFFFF;
    text-transform: uppercase;
    font: 700 2.125rem/0.8 'Fira Sans';
    line-height: 0.8; }
  #modal-stock-miniature #modal-stock-miniature-minimize {
    position: absolute;
    top: -16px;
    right: -16px;
    color: #6b7072;
    font-size: 26px;
    font-weight: bold;
    cursor: pointer; }
    #modal-stock-miniature #modal-stock-miniature-minimize:hover {
      color: #ff7381; }
  #modal-stock-miniature::after {
    position: absolute;
    z-index: -1;
    top: -53px;
    right: -1px;
    content: '';
    background-image: url("../img/anyplace_sprite.png");
    width: 142px;
    height: 152px;
    background-position: -29px -5px; }
  #modal-stock-miniature:hover {
    background: #565E62; }

#modal-stock-miniature-minimized {
  position: fixed;
  z-index: 1000;
  display: none;
  bottom: 100px;
  right: 2%;
  cursor: pointer;
  background-image: url("../img/modal_sprite.png");
  width: 84px;
  height: 91px;
  background-position: -414px -5px; }

/*_____________________________________________________
______________________ modals _______________________*/
.modal-base {
  position: relative;
  margin: 20px auto 0;
  max-width: 990px;
  width: 100%;
  /* modal-designer */
  /* modal-develop-design */
  /* modal-callback */
  /* modal-catalog */
  /* modal-ask-manager */
  /* modal-calculate-kitchen */
  /* modal-closet */
  /* modal-thanks */
  /*
#modal-stock {
 
  
      #modal-stock .bottom-part form input[type="text"],
      #modal-stock .bottom-part form input[type="email"] {
        margin: 0 15px 0 0;
        padding: 5px;
        width: 240px;
        color: #dadada;
        border: none;
        outline: none;
        box-shadow: none;
        border-bottom: 2px solid #636163;
        text-align: center;
        background: transparent;
        font: 16px 'FiraSansLight'; }
        #modal-stock .bottom-part form input[type="text"]:focus,
        #modal-stock .bottom-part form input[type="email"]:focus {
          border-bottom-color: #FFFFFF; }
        #modal-stock .bottom-part form input[type="text"]:hover,
        #modal-stock .bottom-part form input[type="email"]:hover {
          border-bottom-color: #FFFFFF; }
      #modal-stock .bottom-part form .input-wrap {
        position: relative;
        display: inline-block; }
      #modal-stock .bottom-part form .stock-tooltip-text {
        position: absolute;
        top: -97px;
        right: 37px;
        display: none;
        width: 250px;
        padding: 10px 20px;
        background: #FFECBA;
        color: #393538;
        border-radius: 10px 10px 0 10px;
        font: 16px 'FiraSansLight'; }
      #modal-stock .bottom-part form .stock-tooltip-icon {
        position: absolute;
        cursor: pointer;
        display: block;
        top: 5px;
        right: 13px;
        width: 23px;
        height: 23px;
        background: url("../img/modal-stock_for-input.png") center no-repeat; }
      #modal-stock .bottom-part form .stock-tooltip-icon:hover + .stock-tooltip-text {
        display: block; }
      #modal-stock .bottom-part form input[type="submit"] {
        margin: 0 0 0 30px;
        padding: 10px 50px;
        background: #FFBA00;
        border: none;
        outline: none;
        color: #252E36;
        border-radius: 50px;
        font: 16px 'FiraSansMedium';
        cursor: pointer; }
        #modal-stock .bottom-part form input[type="submit"]:hover {
          background: #cc9500; }
      #modal-stock .bottom-part form input.error {
        border-bottom-color: #f32e2e; }
      #modal-stock .bottom-part form label.error {
        font-size: 0.8rem;
        color: #f32e2e; }*/ }
  .modal-base .min-order-title {
    margin-top: 0;
    color: #f71818;
    font-size: 1.1rem; }
  .modal-base .flex-container {
    display: flex; }
  .modal-base .content {
    position: relative;
    z-index: 100;
    flex-shrink: 0;
    width: calc(100% - 435px);
    background: #393538;
    color: #FFFFFF; }
  .modal-base .designer {
    display: flex;
    align-items: flex-end;
    margin-top: -50px;
    padding: 0 0 20px 50px;
    color: #c9c9c9;
    font: 400 0.9375rem/1.1 'Fira Sans'; }
    .modal-base .designer p {
      margin: 0 0 10px 20px; }
  .modal-base .call {
    position: relative;
    z-index: 10;
    background-color: #FFECBA;
    padding: 35px 0 35px 50px;
    color: #393538; }
    .modal-base .call h3 {
      font: 700 2.2rem/1.1 'Fira Sans'; }
    .modal-base .call p {
      margin-top: 15px;
      font: 400 1.0625rem/1.1 'Fira Sans'; }
    .modal-base .call::after {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 100%;
      content: '';
      width: 0;
      height: 0;
      border-top: 95px solid transparent;
      border-bottom: 95px solid transparent;
      border-left: 20px solid #FFECBA; }
  .modal-base .icon-designer {
    background-image: url("../img/design-variants_sprite.png"); }
  .modal-base .icon-designer-ilona {
    background-image: url("../img/modal_sprite.png");
    width: 142px;
    height: 143px;
    background-position: -262px -5px; }
  .modal-base .icon-director {
    background-image: url("../img/modal_sprite.png");
    width: 142px;
    height: 143px;
    background-position: -876px -5px; }
  .modal-base .icon1 {
    width: 34px;
    height: 34px;
    background-position: -5px -5px; }
  .modal-base .icon2 {
    width: 29px;
    height: 35px;
    background-position: -49px -5px; }
  .modal-base .icon3 {
    width: 42px;
    height: 31px;
    background-position: -88px -5px; }
  .modal-base .icon4 {
    width: 36px;
    height: 36px;
    background-position: -140px -5px; }
  .modal-base .icon-wrapper {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #393538;
    border: 2px solid #FFECBA;
    border-radius: 50%; }
  .modal-base .additional {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px 50px; }
    .modal-base .additional-title {
      margin: 30px 0;
      padding-left: 50px;
      color: #FFECBA;
      font: 700 1.0625rem  'Fira Sans'; }
    .modal-base .additional-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      width: 50%;
      font: 500 0.85rem 'Fira Sans'; }
      .modal-base .additional-item p {
        margin-left: 20px;
        max-width: 120px; }
  .modal-base .form-title {
    margin-bottom: 50px;
    font: 400 1.125rem 'Fira Sans'; }
    .modal-base .form-title span {
      font: 500 1.44rem/1.4 'Fira Sans'; }
  .modal-base .form-wrapper {
    flex-shrink: 0; }
  .modal-base .form select {
    width: 100%; }
  .modal-base .form textarea {
    padding: 10px;
    width: 100%;
    min-height: 100px;
    resize: vertical;
    border-radius: 5px;
    border: 2px solid #a3a0a7;
    outline: none; }
    .modal-base .form textarea:focus {
      border-color: #000000; }
    .modal-base .form textarea.error {
      border-color: #FF1100; }
  .modal-base .form .input-wrapper {
    margin-bottom: 20px; }
  .modal-base .form .bottom-text {
    width: 260px; }
  .modal-base .form input[type="submit"] {
    padding: 20px 10px; }
  .modal-base.modal-designer .form input[type="submit"] {
    /*margin-bottom: 40px;*/ }
  .modal-base.modal-designer .flex-container {
    margin-top: 40px; }
  .modal-base.modal-develop-design {
    max-width: 460px; }
    .modal-base.modal-develop-design .form-wrapper {
      max-width: 100%; }
  .modal-base.modal-callback {
    max-width: 460px; }
    .modal-base.modal-callback .form-wrapper {
      max-width: 100%; }
    .modal-base.modal-callback .form::before {
      position: absolute;
      top: -54px;
      background-image: url("../img/modal_sprite.png");
      width: 247px;
      height: 78px;
      background-position: -5px -5px; }
  .modal-base.modal-catalog {
    position: relative;
    max-width: 900px; }
    .modal-base.modal-catalog .content {
      background: transparent;
      width: 460px;
      overflow: hidden; }
    .modal-base.modal-catalog .modal-catalog-img {
      position: absolute;
      top: -40px;
      left: -40px;
      max-width: none;
      width: 550px;
      height: auto; }
    .modal-base.modal-catalog .form-wrapper {
      z-index: 1000; }
    .modal-base.modal-catalog .form input[type="submit"] {
      padding: 20px 30px; }
  .modal-base.modal-ask-manager {
    max-width: 460px; }
    .modal-base.modal-ask-manager .form-wrapper {
      max-width: 100%; }
    .modal-base.modal-ask-manager .form::before {
      position: absolute;
      top: -54px;
      background-image: url("../img/modal_sprite.png");
      width: 247px;
      height: 78px;
      background-position: -5px -5px; }
  .modal-base.modal-calculate-kitchen {
    max-width: 1100px; }
    .modal-base.modal-calculate-kitchen .content {
      width: calc(100% - 435px); }
    .modal-base.modal-calculate-kitchen .form-wrapper {
      max-width: 435px; }
    .modal-base.modal-calculate-kitchen #modal-calculate-kitchen-img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .modal-base.modal-calculate-kitchen .bottom-text {
      max-width: 200px;
      margin-bottom: -20px; }
    .modal-base.modal-calculate-kitchen .form-title {
      margin-bottom: 25px; }
    .modal-base.modal-calculate-kitchen .form input[type="submit"] {
      margin-top: 10px; }
  .modal-base.modal-closet .call {
    margin: 80px 0; }
  .modal-base.modal-closet .icon-veshalka {
    flex-shrink: 0;
    background-image: url("../img/modal_sprite.png");
    width: 60px;
    height: 60px;
    background-position: -1151px -5px; }
  .modal-base.modal-closet .form {
    padding-top: 20px; }
    .modal-base.modal-closet .form-title {
      margin-bottom: 30px; }
    .modal-base.modal-closet .form input[type="submit"] {
      margin-top: 0; }
  .modal-base.modal-closet .bottom-text {
    width: 260px; }
  .modal-base.modal-thanks {
    padding: 70px 40px 50px;
    max-width: 540px;
    background: #ffecba;
    text-align: center; }
    .modal-base.modal-thanks .wrapper {
      position: relative;
      padding: 50px 20px 0;
      border: 1px solid #4b4453; }
      .modal-base.modal-thanks .wrapper::before {
        position: absolute;
        top: -54px;
        left: calc(50% - 124px);
        content: '';
        background: url(../img/map_addresses-top.png);
        width: 247px;
        height: 113px; }
    .modal-base.modal-thanks h3 {
      font: 500 1.4375rem 'Fira Sans';
      text-transform: uppercase; }
    .modal-base.modal-thanks .contact {
      margin: 15px 0 25px;
      font: 500 1.125rem 'Fira Sans'; }
    .modal-base.modal-thanks .director {
      margin-bottom: 30px; }
      .modal-base.modal-thanks .director p {
        margin-top: 10px;
        font-weight: 300;
        font-style: italic; }
    .modal-base.modal-thanks .signature {
      position: relative;
      margin-bottom: -40px;
      display: inline-block;
      background-color: #ffecba;
      background-image: url("../img/modal_sprite.png");
      width: 113px;
      height: 61px;
      background-position: -1028px -5px; }
      .modal-base.modal-thanks .signature::before, .modal-base.modal-thanks .signature::after {
        position: absolute;
        top: 0;
        content: '';
        height: 100%;
        width: 20px;
        background-color: #ffecba; }
      .modal-base.modal-thanks .signature::before {
        left: -20px; }
      .modal-base.modal-thanks .signature::after {
        right: -20px; }
  .modal-base.modal-stock {
    margin: 80px auto 10px;
    padding: 80px 0 30px;
    max-width: 990px;
    background: #393538;
    line-height: 1.2;
    color: #FFFFFF;
    background: url("../img/modal-stock_bg.jpg") center no-repeat;
    background-size: cover; }
    .modal-base.modal-stock .mfp-close {
      top: -86px;
      right: 0px; }
    .modal-base.modal-stock .modal-stock-header {
      position: absolute;
      z-index: 10;
      top: -85px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      padding: 10px 0;
      width: 100%;
      max-width: 620px;
      background: #FFECBA;
      text-align: center; }
      .modal-base.modal-stock .modal-stock-header .your-gift {
        position: relative;
        display: inline-block;
        color: #E61D31;
        text-transform: uppercase;
        font: 700 3rem 'Fira Sans'; }
        .modal-base.modal-stock .modal-stock-header .your-gift::after {
          position: absolute;
          z-index: 1;
          top: 27px;
          left: -15px;
          content: '';
          background-image: url("../img/modal_sprite.png");
          width: 358px;
          height: 43px;
          background-position: -508px -5px; }
      .modal-base.modal-stock .modal-stock-header .to-kitchen {
        color: #393538;
        text-transform: uppercase;
        font: 700 1.375rem 'Fira Sans';
        letter-spacing: 2px; }
      .modal-base.modal-stock .modal-stock-header .book-it {
        color: #393538;
        font: 400 1.25rem 'Fira Sans'; }
      .modal-base.modal-stock .modal-stock-header::after {
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 0;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 310px 0 310px;
        border-color: #FFECBA transparent transparent transparent; }
    .modal-base.modal-stock .main-part {
      position: relative;
      margin: 20px 40px 0;
      padding: 50px 0 50px 45%;
      box-sizing: border-box;
      border-radius: 20px;
      border: 2px solid #555354;
      color: #FFFFFF;
      text-transform: uppercase; }
      .modal-base.modal-stock .main-part::before {
        position: absolute;
        z-index: 10;
        top: -88px;
        left: -28px;
        content: '';
        width: 360px;
        height: 400px;
        background: url("../img/modal-stock_sale.png");
        background-size: cover; }
      .modal-base.modal-stock .main-part strong {
        display: block;
        margin: 10px 0;
        color: #e61d31;
        font: 500 2.625rem/1 'Fira Sans';
        letter-spacing: 2px; }
        .modal-base.modal-stock .main-part strong span {
          font-size: 3.125rem; }
      .modal-base.modal-stock .main-part .period {
        font: 300 1.5625rem/1.1 'Fira Sans';
        color: #FFFFFF; }
      .modal-base.modal-stock .main-part .more-info {
        margin: 10px 0 0;
        font: 300 1.125rem/1.1 'Fira Sans';
        color: #ffecba; }
    .modal-base.modal-stock .bottom-part {
      margin: 30px 0 0;
      text-align: center; }
      .modal-base.modal-stock .bottom-part strong {
        display: block;
        text-transform: uppercase;
        font: 500 1.25rem/1.1 'Fira Sans';
        letter-spacing: 2px; }
      .modal-base.modal-stock .bottom-part p {
        margin: 5px 0 15px;
        color: #dadada;
        font: 400 0.875rem 'Fira Sans'; }
    .modal-base.modal-stock .form {
      padding: 0 50px;
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .modal-base.modal-stock .form::before {
        content: none; }
      .modal-base.modal-stock .form .input-wrapper {
        margin: 0; }
      .modal-base.modal-stock .form input[type="text"],
      .modal-base.modal-stock .form input[type="tel"],
      .modal-base.modal-stock .form input[type="email"] {
        background: transparent;
        color: #FFFFFF; }
        .modal-base.modal-stock .form input[type="text"]:focus,
        .modal-base.modal-stock .form input[type="tel"]:focus,
        .modal-base.modal-stock .form input[type="email"]:focus {
          border-bottom-color: #FFFFFF; }
      .modal-base.modal-stock .form input[type="submit"] {
        margin: 0;
        padding: 10px;
        height: 45px; }
      .modal-base.modal-stock .form .tooltip-text {
        color: #333333; }

/*_____________________________________________________
_____________________ plugins _______________________*/
.mfp-close-btn-in .mfp-close {
  width: 40px;
  height: 40px;
  top: -20px;
  right: -20px;
  color: #5a4a42;
  background-color: #FFFFFF;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 1;
  opacity: 1; }
  .mfp-close-btn-in .mfp-close:hover {
    background: #ffecba; }

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

.owl-controls .owl-nav {
  display: flex; }

.owl-controls .arrow-left,
.owl-controls .arrow-right {
  position: relative;
  width: 56px;
  height: 56px;
  border: 2px solid #c3c2c2;
  border-radius: 50%; }
  .owl-controls .arrow-left::before,
  .owl-controls .arrow-right::before {
    position: absolute;
    top: 18px;
    content: '';
    width: 15px;
    height: 15px; }
  .owl-controls .arrow-left:hover,
  .owl-controls .arrow-right:hover {
    border-color: #f4d579;
    background-color: #f4d579; }
    .owl-controls .arrow-left:hover::before,
    .owl-controls .arrow-right:hover::before {
      border-color: #cc5d3f; }

.owl-controls .arrow-left::before {
  left: 22px;
  border-left: 4px solid #c3c2c2;
  border-bottom: 4px solid #c3c2c2;
  transform: rotate(45deg); }

.owl-controls .arrow-right {
  margin-left: 25px; }
  .owl-controls .arrow-right::before {
    right: 22px;
    border-right: 4px solid #c3c2c2;
    border-bottom: 4px solid #c3c2c2;
    transform: rotate(-45deg); }

.select2-container {
  width: 100% !important;
  height: 44px;
  border: 2px solid #a3a0a7;
  border-radius: 5px;
  /*&:focus {
			border-bottom-color: #000000;
		}*/ }

/*
.select2-selection {
	padding: 6px;
}

.select2-container .select2-selection--single {
	height: 40px;
	border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	background: #FFBA00;
	border-radius: 50%;

	&::before {
		position: absolute;
		top: 7px;
		left: 9px;
		content: '';
		width: 12px;
		height: 12px;
		border-left: 2px solid #cc5d3f;
		border-bottom: 2px solid #cc5d3f;
		transform: rotate(-45deg);
	}

	b {
		display: none;
	}
}

.select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow {
	&::before {
		top: 12px;
		transform: rotate(135deg);
	}
}

.select2-container--default .select2-results>.select2-results__options {
	min-height: 210px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: lighten(#cc5d3f, 10%);
}*/
.chosen-container {
  width: 100% !important;
  height: 44px;
  border: 2px solid #a3a0a7;
  border-radius: 5px;
  outline: none; }
  .chosen-container:focus {
    border-color: #000000; }

.chosen-container-single .chosen-single {
  height: 100%;
  background: none;
  border: none; }

.chosen-container-single .chosen-single span {
  height: 100%;
  padding: 10px;
  font: 300 1rem 'Fira Sans'; }

.chosen-container-single .chosen-single div {
  width: 36px;
  padding-top: 5px; }

.chosen-container-single .chosen-single div b {
  position: relative;
  width: 30px;
  height: 30px;
  background: #FFBA00;
  border-radius: 50%; }
  .chosen-container-single .chosen-single div b::before {
    position: absolute;
    top: 7px;
    left: 9px;
    content: '';
    width: 12px;
    height: 12px;
    border-left: 2px solid #cc5d3f;
    border-bottom: 2px solid #cc5d3f;
    transform: rotate(-45deg); }

.chosen-container-active.chosen-with-drop .chosen-single div b::before {
  top: 12px;
  transform: rotate(135deg); }

.chosen-container .chosen-results {
  margin: 0;
  padding: 0; }

.chosen-container .chosen-results li {
  padding: 10px;
  font: 300 1rem 'Fira Sans'; }

/*_____________________________________________________
______________________ media ________________________*/
/*********************************************************/
/*********************** min-width************************/
/*********************************************************/
/*________________________________________________________
__________________ min-width: 768px  ___________________*/
@media (min-width: 768px) {
  .catalog {
    padding-bottom: 280px; }
    .catalog .magazine {
      display: block;
      min-width: 600px;
      width: 650px;
      height: 789px;
      background: url("../img/catalog_magazine.png"); }
      .catalog .magazine-wrapper {
        position: absolute;
        z-index: 0;
        top: 235px;
        right: calc(50% - 100px);
        display: block;
        width: 650px; }
      .catalog .magazine-inside {
        position: absolute;
        right: -300px;
        bottom: 50px;
        color: #a8a7a8;
        font-size: 1.125rem; }
        .catalog .magazine-inside::before {
          position: absolute;
          top: 55px;
          left: -265px;
          content: '';
          width: 248px;
          height: 43px;
          background: url("../img/catalog_arrow.png"); }
  .modal-base.modal-catalog .modal-catalog-img {
    position: absolute;
    top: -40px;
    left: -40px;
    display: block;
    width: 500px;
    height: 604px;
    background: url("../img/catalog_magazine.png"); }
  .enjoy .fittings::before {
    position: absolute;
    right: -170px;
    top: -429px;
    content: '';
    width: 621px;
    height: 429px;
    background: url("../img/enjoy_girl.png"); }
  .faq .questions::before {
    position: absolute;
    top: -30px;
    right: calc(100% + 100px);
    content: '';
    width: 746px;
    height: 608px;
    background: url("../img/faq_kitchen.png"); }
  .our-goal {
    background: url("../img/perfect-kitchen_big-kitchen.png") no-repeat center;
    background-size: cover; } }

/*________________________________________________________
__________________ min-width: 1400px  __________________*/
@media (min-width: 1400px) {
  .base .content {
    margin-top: 400px; } }

/*********************************************************/
/*********************** max-width ***********************/
/*********************************************************/
/*________________________________________________________
__________________ max-width: 1110px  __________________*/
@media (max-width: 1120px) {
  .closet .container {
    width: 100%; }
  .closet .tab-gallery {
    width: 50%; }
  .testimonials .container {
    width: 100%; }
  .testimonials-item-photo {
    width: 50%; }
  .our-works .container {
    width: 100%; }
  .our-works .tab-gallery {
    width: 50%; } }

/*________________________________________________________
__________________ max-width: 992px  ___________________*/
@media (max-width: 992px) {
  .our-works .tab-wrapper {
    flex-wrap: wrap; }
  .our-works .tab-gallery {
    width: 100%; }
    .our-works .tab-gallery .gallery .owl-controls {
      top: calc(100% + 20px);
      left: 50%;
      transform: translateX(-50%); }
  .our-works .btn {
    margin: 0; }
    .our-works .btn-wrapper {
      text-align: center; }
  .our-works .catalog .magazine-inside {
    position: absolute;
    right: -250px;
    bottom: 60px;
    color: #a8a7a8;
    font-size: 1.125rem; }
  .our-works .tabs {
    margin-left: 0;
    justify-content: center; }
  .enjoy .fittings {
    justify-content: center; }
    .enjoy .fittings-item:nth-of-type(8) {
      width: 300px; }
      .enjoy .fittings-item:nth-of-type(8) .fittings-item-info {
        bottom: -149px; }
      .enjoy .fittings-item:nth-of-type(8):hover .fittings-item-info {
        bottom: 0; }
  .factory .video-wrapper {
    flex-wrap: wrap;
    margin-top: 30px;
    height: auto; }
    .factory .video-wrapper p {
      text-align: center;
      margin-bottom: 20px; }
  .factory .quote-wrapper {
    margin-top: 50px; }
  .factory .advantages-item {
    min-height: 260px; }
  .perfect-kitchen .girl {
    height: auto; }
    .perfect-kitchen .girl-wrapper {
      position: static;
      margin-top: 30px;
      text-align: center;
      width: 100%; }
      .perfect-kitchen .girl-wrapper::before {
        content: none; }
  .perfect-kitchen .steps {
    margin: 30px 0 0 15%; }
    .perfect-kitchen .steps-item:nth-of-type(2), .perfect-kitchen .steps-item:nth-of-type(3), .perfect-kitchen .steps-item:nth-of-type(4), .perfect-kitchen .steps-item:nth-of-type(5) {
      margin-left: 0; }
    .perfect-kitchen .steps-item:nth-of-type(6) {
      margin-left: -25px; }
  .testimonials .owl-controls {
    right: calc(50% - 68px); }
  .testimonials-item {
    height: auto;
    flex-direction: column; }
    .testimonials-item-photo {
      margin: 0 auto;
      max-height: 500px;
      width: auto;
      max-width: 500px; }
    .testimonials-item-info {
      padding: 20px; }
    .testimonials-item-title {
      text-align: center; }
    .testimonials-item-author {
      margin-top: 0;
      align-items: center;
      justify-content: center; }
      .testimonials-item-author-name {
        margin-bottom: 0; }
  .faq .questions {
    margin-left: 270px; }
  .design-variants .flex-container {
    flex-direction: column; }
  .design-variants .samples {
    margin-bottom: 40px; }
  .closet .tabs {
    justify-content: center;
    padding-left: 0; }
    .closet .tabs li a {
      padding: 20px 35px; }
  .closet .tab-wrapper {
    flex-wrap: wrap; }
  .closet .tab-gallery {
    width: 100%; }
    .closet .tab-gallery .gallery .owl-controls {
      top: calc(100% + 20px);
      left: 50%;
      transform: translateX(-50%); }
  .closet .btn {
    left: 50%;
    transform: translateX(-50%); }
  .closet .catalog .magazine-inside {
    position: absolute;
    right: -250px;
    bottom: 60px;
    color: #a8a7a8;
    font-size: 1.125rem; }
  .closet .bottom-part .cards {
    justify-content: center; }
  footer .flex-container {
    flex-wrap: wrap; }
  footer .logo {
    width: 50%; }
  footer .phone-wrapper {
    width: 50%;
    text-align: right; }
  footer .developer {
    margin-top: 30px;
    width: 100%;
    text-align: center; }
  .modal-base {
    max-width: 435px; }
    .modal-base.modal-calculate-kitchen {
      max-width: 435px; }
    .modal-base .content {
      display: none; }
    .modal-base.modal-stock {
      margin-top: 100px; }
      .modal-base.modal-stock .bottom-part {
        margin-top: 50px; }
      .modal-base.modal-stock .form {
        flex-direction: column; }
        .modal-base.modal-stock .form .input-wrapper {
          margin-bottom: 15px; }
        .modal-base.modal-stock .form input[type="submit"] {
          margin-top: 10px;
          padding: 15px;
          height: auto; } }

/*________________________________________________________
__________________ max-width: 767px  ___________________*/
@media (max-width: 767px) {
  header .delivery {
    display: none; }
  section {
    padding: 50px 0; }
  .section-header {
    font-size: 2.5rem;
    text-align: center; }
    .section-header .subheader {
      font-size: 1.8rem; }
  .base {
    background-position-x: 65%; }
    .base .content {
      flex-direction: column;
      text-align: center;
      align-items: center; }
    .base .order-kithen {
      text-align: center;
      text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF; }
    .base .order-economy {
      margin-top: 20px;
      text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF; }
    .base .order-guarantee {
      color: #000000;
      text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF; }
    .base .btn-wrapper {
      margin-top: 30px;
      justify-content: center; }
  .our-works .section-header {
    margin-left: 0; }
  .catalog .form-flex-container {
    justify-content: center; }
  .our-goal {
    margin-bottom: 0;
    padding: 50px 0;
    height: auto;
    text-align: center; }
    .our-goal p {
      text-align: center; }
  .enjoy {
    padding-top: 50px; }
  .factory .quote {
    margin-top: 20px; }
    .factory .quote-wrapper {
      flex-direction: column; }
    .factory .quote-title {
      font-size: 1.6rem;
      text-align: center; }
    .factory .quote-author {
      justify-content: center; }
  .factory .advantages {
    flex-direction: column;
    align-items: center; }
    .factory .advantages-item {
      margin-bottom: 80px;
      padding: 10px;
      width: 100%;
      max-width: 400px;
      min-height: auto; }
      .factory .advantages-item-title {
        margin: 10px 0;
        font-size: 1.11rem; }
  .testimonials-item {
    /*&-photo {
				max-height: 500px;
				max-width: 500px;
			}	*/ }
  .testimonials-carousel {
    margin-top: 30px; }
  .faq .questions {
    min-height: auto;
    margin: 50px 0 0 70px; }
  .faq .no-answer {
    font-size: 2.5rem; }
  .faq .ask-now {
    font-size: 1.8rem; }
  .design-variants .additional {
    flex-wrap: wrap; }
    .design-variants .additional-title {
      text-align: center; }
    .design-variants .additional-item {
      padding-bottom: 20px;
      width: 49%;
      min-height: auto; }
  .design-variants .samples-title {
    text-align: center; }
  .closet .tabs {
    justify-content: center;
    margin-left: 0; }
    .closet .tabs li a {
      padding: 15px 15px;
      font-size: 1rem; }
  .closet .bottom-part .interior {
    margin-left: 0;
    text-align: center;
    font-size: 1.5rem; }
  .closet .bottom-part .details {
    margin-left: 0;
    justify-content: center; }
  .modal-base.modal-stock .modal-stock-header::after {
    left: 50%;
    transform: translateX(-50%); }
  .modal-base.modal-stock .main-part {
    margin: 20px 20px 0;
    padding: 400px 0 30px;
    text-align: center; }
    .modal-base.modal-stock .main-part::before {
      top: -10px;
      left: 50%;
      transform: translateX(-50%); } }

/*________________________________________________________
__________________ max-width: 600px  ___________________*/
/*________________________________________________________
__________________ max-width: 479px  ___________________*/
@media (max-width: 479px) {
  html {
    font-size: 14px; }
  .mfp-close-btn-in .mfp-close {
    top: -35px;
    right: 0px; }
  .section-header {
    font-size: 2rem; }
    .section-header .subheader {
      font-size: 1.5rem; }
  .form {
    padding: 30px 15px 0; }
    .form-wrapper {
      padding: 60px 15px 25px; }
    .form input[type="submit"] {
      width: 100%;
      font-size: 1.11rem; }
    .form .userfile::after {
      padding: 20px 0 0 25px;
      line-height: 1.2; }
    .form .tooltip-text {
      top: -48px; }
  header {
    flex-wrap: wrap; }
    header .logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      header .logo-text {
        width: 50%;
        margin: 5px 0 0 5px;
        text-align: right; }
      header .logo a {
        width: 50%; }
    header .phone {
      font-size: 1.5rem;
      margin-left: 10px; }
      header .phone::before {
        top: 9px; }
      header .phone-wrapper {
        margin-top: 10px;
        width: 100%;
        text-align: center; }
        header .phone-wrapper p:first-of-type {
          display: none; }
        header .phone-wrapper p {
          margin-top: -5px; }
  .base .btn {
    width: 100%;
    max-width: 382px; }
    .base .btn::after {
      bottom: -111px;
      right: -44px; }
  .our-works .tabs li a {
    padding: 12px; }
  .our-works .tab-content {
    padding: 20px; }
  .our-works .tab-description {
    margin-left: 0; }
    .our-works .tab-description ul {
      font-size: 1.11rem; }
  .our-works .btn {
    max-width: 260px; }
  .catalog .form-flex-container {
    margin-top: 40px; }
  .enjoy .fittings-item:nth-of-type(2) .fittings-item-info, .enjoy .fittings-item:nth-of-type(3) .fittings-item-info {
    bottom: -120px; }
  .enjoy .fittings-item:nth-of-type(2):hover .fittings-item-info, .enjoy .fittings-item:nth-of-type(3):hover .fittings-item-info {
    bottom: 0; }
  .enjoy .fittings-item:nth-of-type(4) .fittings-item-info, .enjoy .fittings-item:nth-of-type(5) .fittings-item-info {
    bottom: -105px; }
  .enjoy .fittings-item:nth-of-type(4):hover .fittings-item-info, .enjoy .fittings-item:nth-of-type(5):hover .fittings-item-info {
    bottom: 0; }
  .enjoy .fittings-item:nth-of-type(8) .fittings-item-info {
    bottom: -127px; }
  .enjoy .fittings-item:nth-of-type(8):hover .fittings-item-info {
    bottom: 0; }
  .factory .video {
    width: 350px;
    height: 158px; }
    .factory .video-wrapper p {
      font-size: 1rem; }
  .testimonials .owl-carousel .owl-item .testimonials-item-author img {
    width: 100px;
    height: 100px; }
  .testimonials-item-author-name {
    margin-left: 10px;
    line-height: 1.2; }
  .perfect-kitchen .steps {
    margin-left: 30px; }
  .faq .questions {
    margin-left: 58px; }
    .faq .questions-item::before {
      left: -50px; }
  .design-variants .tab-content {
    padding: 15px; }
  .design-variants .tabs li a {
    font-size: 1rem; }
  .design-variants .additional {
    justify-content: center; }
    .design-variants .additional-item {
      max-width: 400px;
      width: 100%; }
  .design-variants .form .bottom-text {
    width: 220px; }
  .closet .btn {
    width: calc(100% - 20px);
    padding: 25px 5px 20px; }
  .closet .tab-description {
    margin-left: 0; }
  .closet .tab-content {
    padding: 20px; }
  .closet .tabs li {
    width: 25%;
    text-align: center; }
    .closet .tabs li a {
      padding: 15px 7px;
      font-size: 0.75rem; }
  .closet .bottom-part .card-info {
    bottom: -100px; }
  .closet .bottom-part .card:nth-of-type(3) .card-info {
    bottom: -115px; }
  .closet .bottom-part .card:nth-of-type(3):hover .card-info {
    bottom: 0; }
  footer .flex-container {
    flex-wrap: wrap; }
  footer .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    footer .logo-text {
      width: 50%;
      margin: 5px 0 0 5px;
      text-align: right; }
    footer .logo a {
      width: 50%; }
  footer .phone {
    font-size: 1.5rem;
    margin-left: 10px; }
    footer .phone::before {
      top: 9px; }
    footer .phone-wrapper {
      margin-top: 10px;
      width: 100%;
      text-align: center; }
      footer .phone-wrapper p:first-of-type {
        display: none; }
      footer .phone-wrapper p {
        margin-top: -5px; }
  .modal-base.modal-thanks {
    padding: 65px 15px 40px; }
  .modal-base.modal-stock .modal-stock-header::after {
    border-width: 20px 180px 0 180px; }
  .modal-base.modal-stock .modal-stock-header .your-gift {
    margin-top: 30px; }
    .modal-base.modal-stock .modal-stock-header .your-gift::after {
      content: none; } }
