.modal-base {
	position: relative;
	margin: 20px auto 0;
	max-width: 990px;
	width: 100%;

	.min-order-title {
		margin-top: 0;
		color: #f71818;
		font-size: 1.1rem;
	}

	.flex-container {
		display: flex;
	}

	.content {
		position: relative;
		z-index: 100;
		flex-shrink: 0;
		width: calc(100% - 435px);
		background: #393538;
		color: #FFFFFF;
	}

	.designer {
		display: flex;
		align-items: flex-end;
		margin-top: -50px;
		padding: 0 0 20px 50px;
		color: #c9c9c9;
		font: 400 0.9375rem/1.1 'Fira Sans';

		p {
			margin: 0 0 10px 20px;
		}
	}

	.call {
		position: relative;
		z-index: 10;
		background-color: #FFECBA;
		padding: 35px 0 35px 50px;
		color: #393538;

		h3 {
			font: 700 2.2rem/1.1 'Fira Sans';
		}

		p {
			margin-top: 15px;
			font: 400 1.0625rem/1.1 'Fira Sans';
		}

		&::after {
			position: absolute;
			z-index: 100;
			top: 0;
			left: 100%;
			content: '';
			width: 0; 
			height: 0; 
			border-top: 95px solid transparent;
			border-bottom: 95px solid transparent;
			border-left: 20px solid #FFECBA;
		}
	}

	.icon {
		&-designer {
			background-image: url('../img/design-variants_sprite.png');
		}

		&-designer-ilona {
			background-image: url('../img/modal_sprite.png');
			@include icon(142px, 143px, -262px, -5px);
		}

		&-director {
			background-image: url('../img/modal_sprite.png');
			@include icon(142px, 143px, -876px, -5px);
		}

		&1 {
			@include icon(34px, 34px, -5px, -5px);
		}

		&2 {
			@include icon(29px, 35px, -49px, -5px);
		}

		&3 {
			@include icon(42px, 31px, -88px, -5px);
		}

		&4 {
			@include icon(36px, 36px, -140px, -5px);
		}

		&-wrapper {
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			width: 60px;
			height: 60px;
			background: #393538;
			border: 2px solid #FFECBA;
			border-radius: 50%;
		}
	}

	.additional {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 20px 50px;

		&-title {
			margin: 30px 0;
			padding-left: 50px;
			color: #FFECBA;
			font: 700 1.0625rem  'Fira Sans';
		}

		&-item {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			width: 50%;
			font: 500 0.85rem 'Fira Sans';

			p {
				margin-left: 20px;
				max-width: 120px;
			}
		}
	}

	.form {
		&-title {
			margin-bottom: 50px;
			font: 400 1.125rem 'Fira Sans';

			span {
				font: 500 1.44rem/1.4 'Fira Sans';
			}
		}

		&-wrapper {
			flex-shrink: 0;
		}

		select {
			width: 100%;
		}

		textarea {
			padding: 10px;
			width: 100%;
			min-height: 100px;
			resize: vertical;
			border-radius: 5px;
			border: 2px solid #a3a0a7;
			outline: none;

			&:focus {
				border-color: #000000;
			}

			&.error {
				border-color: #FF1100;
			}
		}

		.input-wrapper {
			margin-bottom: 20px;
		}

		.bottom-text {
			width: 260px;
		}

		input[type="submit"] {
			padding: 20px 10px;
		}
	}


	/* modal-designer */
	&.modal-designer {
		.form {
			input[type="submit"] {
				/*margin-bottom: 40px;*/
			}
		}

		.flex-container {
			margin-top: 40px;
		}
	}

	/* modal-develop-design */
	&.modal-develop-design {
		max-width: 460px;


		.form-wrapper {
			max-width: 100%;
		}
	}

	/* modal-callback */
	&.modal-callback {
		max-width: 460px;

		.form {
			&-wrapper {
				max-width: 100%;
			}

			&::before {
				position: absolute;
				top: -54px;
				background-image: url('../img/modal_sprite.png');
				@include icon(247px, 78px, -5px, -5px);
			}
		}
	}
	
	/* modal-catalog */
	&.modal-catalog {
		position: relative;
		max-width: 900px;

		.content {
			background: transparent;
			width: 460px;
			overflow: hidden;
		}

		.modal-catalog-img {
			position: absolute;
			top: -40px;
			left: -40px;
			max-width: none;
			width: 550px;
			height: auto;
		}

		.form-wrapper {
			z-index: 1000;
		}

		.form {
			input[type="submit"] {
				padding: 20px 30px;
			}
		}
	}

	/* modal-ask-manager */
	&.modal-ask-manager {
		max-width: 460px;

		.form {
			&-wrapper {
				max-width: 100%;
			}
			

			&::before {
				position: absolute;
				top: -54px;
				background-image: url('../img/modal_sprite.png');
				@include icon(247px, 78px, -5px, -5px);
			}
		}
	}

	/* modal-calculate-kitchen */
	&.modal-calculate-kitchen {
		max-width: 1100px;
		
		.content {
			width: calc(100% - 435px);
		}

		.form-wrapper {
			max-width: 435px;
		}

		#modal-calculate-kitchen-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.bottom-text {
			max-width: 200px;
			margin-bottom: -20px;
		}

		.form {
			&-title {
				margin-bottom: 25px;
			}

			input[type="submit"] {
				margin-top: 10px;
			}
		}
	}

	/* modal-closet */
	&.modal-closet {
		.call {
			margin: 80px 0;
		}

		.icon-veshalka {
			flex-shrink: 0;
			background-image: url('../img/modal_sprite.png');
			@include icon(60px, 60px, -1151px, -5px);
		}

		.form {
			padding-top: 20px;

			&-title {
				margin-bottom: 30px;
			}

			input[type="submit"] {
				margin-top: 0;
			}
		}


		.bottom-text {
			width: 260px;
		}

	}
	
	/* modal-thanks */
	&.modal-thanks {
		padding: 70px 40px 50px;
		max-width: 540px;
		background: #ffecba;
		text-align: center;

		.wrapper {
			position: relative;
			padding: 50px 20px 0;
			border: 1px solid #4b4453;
			
			&::before {
				position: absolute;
				top: -54px;
				left: calc(50% - 124px);
				content: '';
				background: url(../img/map_addresses-top.png);
				width: 247px;
				height: 113px;
			}
		}

		h3 {
			font: 500 1.4375rem 'Fira Sans';
			text-transform: uppercase;
		}

		.contact  {
			margin: 15px 0 25px;
			font: 500 1.125rem 'Fira Sans';
		}

		.director {
			margin-bottom: 30px;

			p {
				margin-top: 10px;
				font-weight: 300;
				font-style: italic;
			}
		}
			
		.signature {
			position: relative;
			margin-bottom: -40px;
			display: inline-block;
			background-color: #ffecba;
			background-image: url('../img/modal_sprite.png');
			@include icon(113px, 61px, -1028px, -5px);

			&::before,
			&::after {
				position: absolute;
				top: 0;
				content: '';
				height: 100%;
				width: 20px;
				background-color: #ffecba;
			}

			&::before {
				left: -20px;
			}

			&::after {
				right: -20px;
			}
		}
	}


	&.modal-stock {
		margin: 80px auto 10px;
		padding: 80px 0 30px;
		max-width: 990px;
		background: #393538;
		line-height: 1.2;
		color: #FFFFFF;
		background: url("../img/modal-stock_bg.jpg") center no-repeat;
		background-size: cover; 

		.mfp-close {
			top: -86px;
			right: 0px;
		}

		.modal-stock-header {
			position: absolute;
			z-index: 10;
			top: -85px;
			left: 50%;
			transform: translateX(-50%);
			margin: 0 auto;
			padding: 10px 0;
			width: 100%;
			max-width: 620px;
			background: #FFECBA;
			text-align: center; 

			.your-gift {
				position: relative;
				display: inline-block;
				color: #E61D31;
				text-transform: uppercase;
				font: 700 3rem 'Fira Sans'; 

				&::after {
					position: absolute;
					z-index: 1;
					top: 27px;
					left: -15px;
					content: '';
					background-image: url('../img/modal_sprite.png');
					@include icon(358px, 43px, -508px, -5px);
				}
			}

			.to-kitchen {
				color: #393538;
				text-transform: uppercase;
				font: 700 1.375rem 'Fira Sans';
				letter-spacing: 2px; 
			}

			.book-it {
				color: #393538;
				font: 400 1.25rem 'Fira Sans';
			}

			&::after {
				position: absolute;
				z-index: 1;
				top: 100%;
				left: 0;
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 25px 310px 0 310px;
				border-color: #FFECBA transparent transparent transparent;
			}
		}

		.main-part {
			position: relative;
			margin: 20px 40px 0;
			padding: 50px 0 50px 45%;
			box-sizing: border-box;
			border-radius: 20px;
			border: 2px solid #555354;
			color: #FFFFFF;
			text-transform: uppercase; 

			&::before {
				position: absolute;
				z-index: 10;
				top: -88px;
				left: -28px;
				content: '';
				width: 360px;
				height: 400px;
				background: url("../img/modal-stock_sale.png"); 
				background-size: cover;
			}

			strong {
				display: block;
				margin: 10px 0;
				color: #e61d31;
				font: 500 2.625rem/1 'Fira Sans'; 
				letter-spacing: 2px;

				span {
					font-size: 3.125rem;
				}
			}

			.period {
				font: 300 1.5625rem/1.1 'Fira Sans';
				color: #FFFFFF;
			}
			
			.more-info {
				margin: 10px 0 0;
				font: 300 1.125rem/1.1 'Fira Sans';
				color: #ffecba;
			}
		}

		.bottom-part {
			margin: 30px 0 0;
			text-align: center;

			strong {
				display: block;
				text-transform: uppercase;
				font: 500 1.25rem/1.1 'Fira Sans';
				letter-spacing: 2px;
			} 

			p {
				margin: 5px 0 15px;
				color: #dadada;
				font: 400 0.875rem 'Fira Sans';
			}
		}

		.form {
			padding: 0 50px;
			background: transparent;
			border: none;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&::before {
				content: none;
			}

			.input-wrapper {
				margin: 0;
			}

			input[type="text"],
			input[type="tel"],
			input[type="email"] {
				background: transparent;
				color: #FFFFFF;

				&:focus {
					border-bottom-color: #FFFFFF;
				}
			}

			input[type="submit"] {
				margin: 0;
				padding: 10px;
				height: 45px;
			}

			.tooltip-text {
				color: #333333;
			}
		}
	}


/*
#modal-stock {
 
  
      #modal-stock .bottom-part form input[type="text"],
      #modal-stock .bottom-part form input[type="email"] {
        margin: 0 15px 0 0;
        padding: 5px;
        width: 240px;
        color: #dadada;
        border: none;
        outline: none;
        box-shadow: none;
        border-bottom: 2px solid #636163;
        text-align: center;
        background: transparent;
        font: 16px 'FiraSansLight'; }
        #modal-stock .bottom-part form input[type="text"]:focus,
        #modal-stock .bottom-part form input[type="email"]:focus {
          border-bottom-color: #FFFFFF; }
        #modal-stock .bottom-part form input[type="text"]:hover,
        #modal-stock .bottom-part form input[type="email"]:hover {
          border-bottom-color: #FFFFFF; }
      #modal-stock .bottom-part form .input-wrap {
        position: relative;
        display: inline-block; }
      #modal-stock .bottom-part form .stock-tooltip-text {
        position: absolute;
        top: -97px;
        right: 37px;
        display: none;
        width: 250px;
        padding: 10px 20px;
        background: #FFECBA;
        color: #393538;
        border-radius: 10px 10px 0 10px;
        font: 16px 'FiraSansLight'; }
      #modal-stock .bottom-part form .stock-tooltip-icon {
        position: absolute;
        cursor: pointer;
        display: block;
        top: 5px;
        right: 13px;
        width: 23px;
        height: 23px;
        background: url("../img/modal-stock_for-input.png") center no-repeat; }
      #modal-stock .bottom-part form .stock-tooltip-icon:hover + .stock-tooltip-text {
        display: block; }
      #modal-stock .bottom-part form input[type="submit"] {
        margin: 0 0 0 30px;
        padding: 10px 50px;
        background: #FFBA00;
        border: none;
        outline: none;
        color: #252E36;
        border-radius: 50px;
        font: 16px 'FiraSansMedium';
        cursor: pointer; }
        #modal-stock .bottom-part form input[type="submit"]:hover {
          background: #cc9500; }
      #modal-stock .bottom-part form input.error {
        border-bottom-color: #f32e2e; }
      #modal-stock .bottom-part form label.error {
        font-size: 0.8rem;
        color: #f32e2e; }*/

}