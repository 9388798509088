* {
	box-sizing: border-box;
}

h1, 
h2,
h3,
h4,
p,
ul,
figure,
strong,
blockquote {
	margin: 0;
	padding: 0;
}

h1, 
h2,
h3 {
	font-weight: normal;

}

html {
	overflow-x: hidden;
	font-size: 16px;
}

body {
	width: 100%;
	overflow-x: hidden;
	color: #5a4a42;
	font: 1rem 'Fira Sans';
	font-weight: 300;
	background: #FFFFFF;
	line-height: 1.4;
}

a {
	color: inherit;
	cursor: pointer;
	outline: none;

	&:hover {
		transition: all 0.4s;
		text-decoration: none;
		color: inherit;
	}

	&:focus {
		text-decoration: none;
		outline: none;
		color: inherit;
	}
}

img {
	max-width: 100%;
}

button {
	outline: none;
	border: none;
}

.no-wrap {
	white-space: nowrap;
}

@mixin icon($width, $height, $posX, $posY) {
	width: $width;
	height: $height;
	background-position: $posX $posY;
}

.icon {
	display: inline-block;
}

.container {
	max-width: 940px;
}

section {
	padding: 70px 0;
}


.section-header {
	font: 700 3.125rem/1.1 'Fira Sans';
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #5a4a42;

	.subheader {
		display: block;
		font: 300 2.19rem/1.2 'Fira Sans';
		letter-spacing: 0;
		text-transform: none;
	}
}

.btn {
	display: inline-block;
	padding: 20px;
	width: 300px;
	color: #28323C;
	background: #FFBA00;
	border: none;
	outline: none;
	border-bottom: 1px solid #FF9000;
	border-radius: 100px;
	text-shadow: 0 1px 0 #FFD77D;
	text-align: center;
	font: 700 1.5rem/1 'Fira Sans';

	&:hover {
		background: #FFD666;
	}
}


.form { 
	position: relative;
	padding: 40px 20px 0;
	background: #FFFFFF;
	border: 1px solid #888888;
	text-align: center;
	color: #4b4453;

	&-wrapper {
		position: relative;
		z-index: 10;
		padding: 70px 35px 35px;
		width: 100%;
		max-width: 435px;
		background: #FFFFFF;
	}

	&-title {
		margin-bottom: 25px;
		font: 500 1.44rem/1.2 'Fira Sans';

		span {
			display: block;
			text-transform: uppercase;
			margin-bottom: 10px;
		}
	}

	.input-wrapper {
		position: relative;
		margin: 0 auto 15px;
		width: 100%;
		max-width: 250px;
	}

	.tooltip {
		position: absolute;
		top: 7px;
		right: 0;
		cursor: pointer;

		&-icon {
			display: block;
			background-image: url('../img/anyplace_sprite.png');
			@include icon(23px, 23px, -329px, -5px);

			&:hover {
				& + .tooltip-text {
					display: block;
				}
			}
		}

		&-text {
			position: absolute;
			top: -56px;
			right: 27px;
			display: none;
			width: 250px;
			padding: 10px 20px;
			color: #393538;
			background: #ffba00;
			border: 1px solid #FFAD33;
			border-radius: 10px 10px 0 10px;
			font-size: 0.85rem;
			box-shadow: -5px -5px 2px rgba(0, 0, 0, 0.1);
		}
	}

	.confirm {
		display: flex;
		text-align: left;
		align-items: flex-start;
		max-width: 330px;
		margin-bottom: 20px;

		label {
			margin-left: 8px;
			font: 400 0.875rem/1.1 'Fira Sans';
			cursor: pointer;
		}

		input[type="checkbox"] {
			flex-shrink: 0;
			width: 15px;
			height: 15px;
			outline: none;
		}

		.error ~ label {
			color: #FF1100;
		}

		a {
			display: inline-block;
			line-height: 1;
			border-bottom: 1px solid  #373736;

			&:hover {
				border-bottom-style: dashed;
			}
		}
	}

	input[type="text"],
	input[type="tel"],
	input[type="email"] {
		width: 100%;
		padding: 10px;
		text-align: center;
		border: none;
		outline: none;
		border-bottom: 2px solid #a3a0a7;

		&:focus {
			border-bottom-color: #000000;
		}

		&.error {
			border-bottom-color: #FF1100;
		}
	}

	select {
		width: 100%;
		padding: 10px;
		text-align: center;
		outline: none;
		border: 2px solid #a3a0a7;
		border-radius: 5px;
		background: #FFFFFF;
	}

	input[type="submit"] {
		margin: 20px 0;
		padding: 15px 20px;
		outline: none;
		white-space: normal;
		font-size: 1.25rem;
	}

	.userfile {
		display: block;
		position: relative;
		width: 100%;
		max-width: 330px;
		margin: 0 auto 10px;
		cursor: pointer;
		border: 0;
		height: 40px;
		border-radius: 5px;
		outline: 0;
		font-size: 0.875rem;

		&::before {
			position: absolute;
			z-index: 1;
			top: 10px;
			left: 0;
			content: '';
			background-image: url('../img/anyplace_sprite.png');
			@include icon(22px, 23px, -297px, -5px);
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;

			text-align: center;
			width: 100%;
			height: 100%;
			display: block;
			content: attr(data-text);
			overflow: hidden;
			text-decoration: underline;
			color: #4b4453;
			line-height: 40px;
			border-radius: 5px;
			transition: 200ms all ease;
			background-color: #FFFFFF;
		}

		
	}

	label.error {
		display: none !important;
	}

	.bottom-text {
		margin: 0 auto -15px;
		width: 220px;
		background: #FFFFFF;
		font: 400 0.85rem/1.1 'Fira Sans';

		.rounded {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 25px;
			height: 25px;
			font-weight: 700;
			color: #4b4453;
			background: #ffecba;
			border-radius: 50%;
		}
	}

	&::before {
		position: absolute;
		top: -40px;
		left: calc(50% - 124px);
		content: '';
		width: 247px;
		height: 102px;
		background: url('../img/catalog_form-bg.png');
	}
}
