@import "_normalize";
@import "_fonts";
@import "_variables";
@import "_base";


/*______________________________________________________
________________________ header ______________________*/

header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.logo {
		flex-shrink: 0;

		&-text {
			margin: -10px 0 0 75px;
			line-height: 1;
			font-size: 0.875rem;
		}
	}

	.phone {
		position: relative;
		font: 700 1rem/1.1 'Fira Sans';

		span {
			font-size: 1.81rem;
			color: #E61F31;
		}

		&::before {
			position: absolute;
			top: -1px;
			left: -28px;
			content: '';
			background-image: url('../img/anyplace_sprite.png');
			@include icon(14px, 15px, -181px, -5px);
		}


		&-wrapper {
			flex-shrink: 0;
			text-align: right;
			font-size: 0.875rem;
		}
	}

	.call-back {
		display: inline-block;
		line-height: 1;
		border-bottom: 1px dashed #373736;
	}

	.delivery {
		padding: 15px 20px;
		border: 1px dashed #ded7d1; 
		border-radius: 8px;
		line-height: 1.2;

		span {
			display: inline-block;
			border-bottom: 1px dashed #4b4453;
		}

	}
}


/*______________________________________________________
________________________ base __________________________
_____________________ 1st screen  ____________________*/

.base {
	padding-top: 20px;
	background:url(../img/base_bg.jpg) no-repeat center;
	background-size: cover;
	color: #373736;

	.content {
		display: flex;
		align-items: flex-end;
		margin-top: 210px;
	}

	.order {
		flex: 1;
		color: #4b4453;

		&-kithen {
			color: #4b4453;
			text-transform: uppercase;
			font: 300 1.81rem/0.8 'Fira Sans';

			span {
				display: block;
				color: #e61d31;
				font: 700 4rem/1 'Fira Sans';
				text-shadow: 0 -2px 1px #FFFFFF;
			}
		}

		&-economy {
			margin-top: 40px;
			text-transform: uppercase;
			font: 300 1.81rem/1 'Fira Sans';

			span {
				display: block;
				color: #ff7800;
				font: 700 2.19rem/1 'Fira Sans';
			}
		}

		&-guarantee {
			font-size: 1.25rem;
			line-height: 1.2;
			margin-top: 25px;
		}
	}

	.btn {
		position: relative;
		padding: 17px;
		width: 382px;
		font: 700 1rem/1.2 'Fira Sans';
		text-transform: uppercase;

		&::before {
			position: absolute;
			bottom: -78px;
			left: 0;
			content: '';
			width: 382px;
			height: 78px;
			background: url('../img/base_btn-shadow.png');
		}

		&::after {
			position: absolute;
			bottom: -68px;	
			right: -74px;
			content: '';
			background-image: url('../img/anyplace_sprite.png');
			@include icon(142px, 152px, -29px, -5px);
		}

		&-wrapper {
			margin-bottom: 15px;
			flex: 1;
			text-align: right;
		}
	}
}



/*______________________________________________________
_____________________ our-works ________________________
_____________________ 2nd screen  ____________________*/

.our-works {
	padding-bottom: 100px;
	background: url('../img/united_bg.png');
	color: #564b44;

/*	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 
	}*/

	.container {
		max-width: 1090px;
		width: 1090px;
	}

	.section-header {
		margin-left: 150px;
	}
	
	.tabs {
		position: relative;
		display: flex;
		justify-content: center;
		margin-top: 45px;
		list-style: none;
		overflow: hidden;

		li {
			a {
				display: block;
				padding: 20px 40px;
				color: #564b44;
				text-transform: uppercase;
				font: 500 1rem/1 'Fira Sans';
				text-decoration: none;
			}

			&.active {
				position: relative;

				&::before,
				&::after {
					position: absolute;
					bottom: 0;
					content: '';
					width: 1000px;
					border-bottom: 2px solid #cfbcb4;
				}

				&::before {
					left: 100%;
				}

				&::after {
					right: 100%;
				}
				
				border-top: 2px solid #cfbcb4;
				border-left: 2px solid #cfbcb4;
				border-right: 2px solid #cfbcb4;
				border-radius: 10px 10px 0 0;
			}

			&:not(.active) a:hover {
				color: #999999;
			}
		}
	}

	.tab {
		&-content {
			position: relative;
			padding: 30px;
			border-left: 2px solid #cfbcb4;
			border-right: 2px solid #cfbcb4;
		}

		&-wrapper {
			display: flex;
			margin-bottom: 30px;
		}

		&-gallery {
			flex-shrink: 0;
			width: 555px;
			max-height: 500px;

			.gallery {
				width: 100%;

				img {
					margin: 0 auto;
					max-width: 600px;
				}

				.owl-controls {
					position: absolute;
					top: 20px;
					left: calc(100% + 50px);
				}
			}
		}

		&-description {
			padding-top: 110px;
			margin-left: 50px;

			ul {
				margin: 20px 0;
				list-style: none;
				font-size: 1.25rem;
			}

			li {
				position: relative;
				margin-bottom: 12px;
				padding-left: 40px;
				color: #5a4a42;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 25px;
					height: 25px;
					background-color: #ffd674;
					border-radius: 100%;
				}

				&::after {
					position: absolute;
					top: 7px;
					left: 6px;
					content: '';
					background-image: url('../img/anyplace_sprite.png');
					@include icon(14px, 11px, -5px, -5px);
				}
			}
		}
	}

	.btn {
		position: relative;
		margin: 0 70px;

		&-wrapper {
			margin-top: -34px;
			position: relative;
			overflow: hidden;
			text-align: right;
		}

		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			width: 1000px;
			border-bottom: 2px solid #cfbcb4;
		}

		&::before {
			left: calc(100% + 20px);
		}

		&::after {
			right: calc(100% + 20px);
		}
	}
}



/*______________________________________________________
_______________________ catalog ________________________
_____________________ 3rd screen  ____________________*/

.catalog {
	position: relative;
	background: url('../img/catalog_bg.jpg') no-repeat center;
	background-size: cover;

	.magazine {
		display: none;

		&-wrapper {
			display: none;
		}
	}

	.section-header {
		color: #FFECBA;

		.subheader {
			color: #D7D7D7;
		}
	}

	.form { 
		padding: 40px 10px 0;
		
		&-flex-container {
			display: flex;
			justify-content: flex-end;
			margin-top: 80px;
		}

		input[type="submit"] {
			padding: 20px 10px;
		}
	}
}


/*______________________________________________________
________________________ enjoy _________________________
_____________________ 4th screen  ____________________*/

.enjoy {
	position: relative;
	padding-top: 100px;
	background: url('../img/enjoy_bg.jpg') no-repeat center;
	background-size: cover;

	.section-header {
		color: #99947f;

		.subheader {
			color: #4b4453;
		}
	}


	.fittings {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin-top: 50px;

		&-item {
			position: relative;
			overflow: hidden;
			width: 300px;
			height: 300px;
			text-align: center;

			&:nth-of-type(1),
			&:nth-of-type(2) {

				.fittings-item-info {
					bottom: -149px;
				}
			}

			&:nth-of-type(8) {
				width: 600px;
			}

			&:hover .fittings-item-info {
				bottom: 0;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&-info {
				position: absolute;
				bottom: -127px;
				width: 100%;
				background: rgba(255, 255, 255, 0.85);
				transition: all 0.5s;

				.visible-part {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 12px;
					height: 90px;
					font: 500 1.25rem 'Fira Sans';
				}

				.hidden-part {
					margin: 5px 15px 20px;
					padding: 15px 10px;
					color: #4a4653;
					border: 2px solid #96937f;
				}
			}
		}
	}
}



/*______________________________________________________
_______________________ factory ________________________
_____________________ 5th screen  ____________________*/

.factory {
	background: url('../img/factory_bg.jpg') no-repeat center;
	background-size: cover;

	.section-header {
		color: #FFECBA;

		.subheader {
			color: #D7D7D7;
		}
	}

	.video {
		width: 600px;
   	height: 271px;

   	iframe {
			width: 100%;
			height: 100%;
			border: none;
   	}

		&-wrapper {
			display: flex;
			justify-content: space-around;
			margin-top: 50px;
			padding: 15px;
			height: 120px;
			border: 2px solid #9e9d9e;

			p {
				font: 500 1.25rem 'Fira Sans';
				color: #FFFFFF;
				text-align: right;
			}
		}
	}

	.quote {
		color: #FFFFFF;
		margin-left: 25px;

		&-wrapper {
			display: flex;
			align-items: center;
			margin-top: 240px;
		}

		&-title {
			display: block;
			color: #ffecba;
			font: 500 italic 2.1rem 'Fira Sans';
		}

		&-text {
			margin: 30px 0 20px;
			line-height: 1.2;
		}

		&-author {
			display: flex;
			align-items: center;
			font-style: italic;

			.icon-signature {
				margin-left: 30px;
			}
		}
	}

	.advantages {
		display: flex;
		flex-wrap: wrap;
		margin-top: 100px;

		&-item {
			margin: 0 1%;
			padding: 10px;
			width: 31.3%;
			min-height: 240px;
			background: #ffecba;
			text-align: center;

			&-title {
				display: block;
				margin: 20px 0;
				font: 500 1.375rem/1.2 'Fira Sans';
			}
		}
	}

	.icon {
		display: block;
		background-image: url('../img/factory_sprite.png');

		&1 {
			@include icon(52px, 46px, -5px, -5px);
		}

		&2 {
			@include icon(50px, 49px, -67px, -5px);
		}

		&3 {
			@include icon(50px, 53px, -127px, -5px);
		}

		&-signature {
			@include icon(113px, 62px, -187px, -5px);
		}

		&-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: -60px auto 0;
			width: 96px;
			height: 96px;
			background: #393538;
			border: 2px solid #FFECBA;
			border-radius: 50%;
		}
	}
}


.united-bg {
	background: url('../img/united_bg.png');
}



/*______________________________________________________
___________________ perfect-kitchen ____________________
_____________________ 6th screen  ____________________*/

.perfect-kitchen {
	position: relative;

	.section-header {
		.subheader {
			span {
				display: inline-block;
				border-bottom: 1px dashed #373736;
			}
		}
	}
	
	.girl {
		&-wrapper {
			position: absolute;
			z-index: 1;
			top: 270px;
			width: 550px;
			right: calc(50% + 20px);
		
			&::before {
				position: absolute;
				z-index: -1;
				top: 98px;
				right: -85px;
				content: '';
				width: 713px;
				height: 713px;
				background: url('../img/perfect-kitchen_circle.png');
			}
		}
	}

	.steps {
		position: relative;
		z-index: 10;
		margin: 220px 0 0 390px;

		&-item {
			display: flex;
			align-items: center;
			margin-bottom: 45px;

			&:nth-of-type(2) {
				margin-left: 60px;
			}

			&:nth-of-type(3) {
				margin-left: 95px;
			}

			&:nth-of-type(4) {
				margin-left: 110px;
			}

			&:nth-of-type(5) {
				margin-left: 95px;
			}

			&:nth-of-type(6) {
				margin: 50px 0 0 15px;
			}

			.icon {
				&-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 90px;
					height: 90px;
					background: #ffd674;
					border-radius: 50%;
					flex-shrink: 0;
				}

				&-kitchen {
					display: block;
					width: 51px;
					height: 54px;
					background: url('../img/perfect-kitchen_kitchen-icon.png');
				}
			}

			&-num {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				width: 40px;
				height: 40px;
				color: #5a4a42;
				background: #ffd674;
				font: 700 1.25rem 'Fira Sans';
				border-radius: 50%;
			}

			&-text {
				margin-left: 20px;
				font-weight: 400;
				line-height: 1.2;
				color: #373736;

				span {
					font-weight: 700;
				}

				a {
					color: #5e7fc1;
					text-decoration: underline;

					&:hover {
						color: #ffb700;
					}
				}
			}
		}
	}
}


/*______________________________________________________
_______________________ our goal _______________________
_____________________ 7th screen  ____________________*/

.our-goal {
	position: relative;
	margin-bottom: -100px;
	padding: 0;
	height: 800px;


	p {
		position: relative;
		z-index: 10;
		display: inline-block;
		padding: 20px 50px;
		color: #373736;
		background: #FFFFFF;
		border: 2px dashed #938883;
		border-radius: 8px;
		font: 300 1.375rem/1.2 'Fira Sans';
	}
}


/*______________________________________________________
_____________________ testimonials _____________________
_____________________ 8th screen  ____________________*/

.testimonials {
	padding-top: 0;

	.container {
		max-width: 1090px;
		width: 1090px;
	}

	&-carousel {
		margin-top: 60px;
	}

	.owl-carousel .owl-item img {
		width: auto;
	}

	.owl-controls {
		position: absolute;
		top: 0;
		right: 100px;
	}

	.owl-stage-outer {
		padding-top: 100px;
	}

	&-item {
		display: flex;
		height: 470px;

		&-photo {
			width: 500px;
			flex-shrink: 0;
			height: 100%;

			img {
				width: 100% !important;
				height: 100% !important;
				object-fit: cover;
			}
		}

		&-info {
			position: relative;
			padding: 0 30px 0 50px;
			border: 2px solid #e0dfdf;
			background: #fff;
		}

		&-author {
			display: flex;
			align-items: flex-end;
			margin-top: -70px;

			img {
				display: block;
				width: 140px;
				height: 140px;
				border-radius: 100%;
			}

			&-name {
				margin: 0 0 10px 30px;
				font: 700 1.25rem 'Fira Sans';
			}
		}

		&-title {
			margin: 25px 0 20px;
			font: 700 1.25rem/1.1 'Fira Sans';
			letter-spacing: 2px;
			text-transform: uppercase;
		}

		&-text {
			height: 230px;

			p {
				font: 400 0.875rem/1.3 'Fira Sans';
				margin-bottom: 0.8rem;
			}
		}
	}

}


/*______________________________________________________
________________________ faq ___________________________
_____________________ 9th screen  ____________________*/

.faq {
	position: relative;
	background: url('../img/faq_bg.png');

	.section-header {
		color: #7894CD;
	}
	
	.kitchen {
		display: none;
	}

	.questions {
		position: relative;
		margin: 90px 0 0 350px;
		min-height: 630px;

		&::before {
			position: absolute;
			top: -30px;
			right: calc(100% + 100px);
			content: '';
			width: 746px;
			height: 608px;
			background: url('../img/faq_kitchen.png');
		}

		&-item {
			position: relative;
			margin-bottom: 30px;
			background: transparent;
			border: none;
			box-shadow: none;

			&::before {
				position: absolute;
				top: -10px;
				left: -55px;
				display: flex;
				align-items: center;
				justify-content: center;
				content: '?';
				width: 40px;
				height: 40px;
				text-align: center;
				color: #5a4a42;
				background: #adc6ee;
				border-radius: 100%;
				font: 700 1.875rem/1 'Fira Sans';
			}

			&-trigger {
				color: #373736;
				font-weight: 500;
				cursor: pointer;

				&:hover {
					color: #ffb700;

					span {
						border-color: #ffb700;
					}
				}

				span {
					display: inline-block;
					border-bottom: 1px dashed #373736;
				}
			}

			&-answer {
				margin: 15px 0;
				color: #7b7b7b;
				font: 400 0.875rem 'Fira Sans';

				a {
					display: inline-block;
					color: #5e7fc1;
					text-decoration: underline;
				}

				p {
					margin-bottom: 0.75rem;
				}
			}


			&.active {
				.questions-item-answer {
					display: block;
				}
			}
		}
	}

	.no-answer {
		margin: 50px 0 10px;
		color: #7894CD;
		font: 700 3.125rem/1.1 'Fira Sans';
		text-transform: uppercase;
		letter-spacing: 3px;
	}

	.ask-now {
		display: inline-block;
		font: 2.19rem/1.2 'Fira Sans';
		border-bottom: 1px dashed #4b4453;

		&:hover {
			color: #ffb700;
			border-color: #ffb700;
		}
	}
}



/*______________________________________________________
____________________ design-variants ___________________
_____________________ 10th screen  ___________________*/

.design-variants {
	background: url('../img/design-variants_bg.jpg') no-repeat center;
	background-size: cover;

	.section-header {
		color: #FFECBA;

		.subheader {
			color: #D7D7D7;
		}
	}

	.additional {
		display: flex;
		justify-content: space-between;

		&-title {
			margin: 60px 0 65px;
			color: #ffecba;
			font-weight: 500;
		}

		&-item {
			margin-bottom: 70px;
			padding: 10px;
			width: 24%;
			min-height: 135px;
			background: #ffecba;
			text-align: center;

			&-text {
				margin-top: 10px;
				line-height: 1.2;
			}
		}
	}

	.icon {
		display: block;
		background-image: url('../img/design-variants_sprite.png');

		&1 {
			@include icon(34px, 34px, -5px, -5px);
		}

		&2 {
			@include icon(29px, 35px, -49px, -5px);
		}

		&3 {
			@include icon(42px, 31px, -88px, -5px);
		}

		&4 {
			@include icon(36px, 36px, -140px, -5px);
		}

		&-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: -58px auto 0;
			width: 96px;
			height: 96px;
			background: #393538;
			border: 2px solid #FFECBA;
			border-radius: 50%;
		}
	}

	.flex-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.tab {
		&-content {
			position: relative;
			max-width: 435px;
			height: 400px;
			padding: 25px;
			margin: 0 auto;
			border-top: 2px solid #cfbcb4;
			border-left: 2px solid #cfbcb4;
			border-right: 2px solid #cfbcb4;
		}

		&-pane {
			height: 100%;

			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.samples-title {
		margin-bottom: 20px;
		color: #ffecba;
		font-weight: 500;
	}

	.tabs {
		display: flex;
		list-style: none;
		overflow: hidden;

		li {
			flex: 1;

			a {
				display: block;
				padding: 10px;
				width: 100%;
				color: #a8a7a8;
				text-transform: uppercase;
				font: 500 1.125rem/1 'Fira Sans';
				text-decoration: none;
				text-align: center;
			}

			&.active {
				position: relative;
				border-radius: 0 0 10px 10px;
				border-left: 2px solid #cfbcb4;
				border-right: 2px solid #cfbcb4;
				border-bottom: 2px solid #cfbcb4;
				border-radius: 0 0 10px 10px;

				&::before,
				&::after {
					position: absolute;
					top: 0;
					content: '';
					width: 500px;
					border-bottom: 2px solid #cfbcb4;
				}

				&::before {
					left: 100%;
				}

				&::after {
					right: 100%;
				}
			}

			&:not(.active) a:hover {
				color: #888888;
			}
		}
	}

	.form {
		padding: 20px 10px 0;

		&-title {
			margin-bottom: 15px;
		}

		input[type="submit"] {
			margin-top: 0;
			min-height: 70px;
		}

		.bottom-text {
			width: 260px;
		}
	}
}


/*______________________________________________________
_______________________ closet _________________________
_____________________ 11th screen  ___________________*/

.closet {
	padding: 70px 0 150px;
	background: url('../img/united_bg.png');
	color: #564b44;

	.container {
		max-width: 1090px;
		width: 1090px;
	}

	.btn {
		position: absolute;
		width: 340px;
		right: 100px;
		bottom: -37px;
	}

	.tabs {
		display: flex;
		margin-top: 60px;
		padding-left: 10%;
		list-style: none;
		overflow: hidden;

		li {
			&.active {
				position: relative;
				border-radius: 10px 10px 0 0;

				&::before,
				&::after {
					position: absolute;
					bottom: 0;
					content: '';
					width: 1000px;
					border-bottom: 2px solid #cfbcb4;
				}

				&::before {
					left: 100%;
				}

				&::after {
					right: 100%;
				}
				
				border-top: 2px solid #cfbcb4;
				border-left: 2px solid #cfbcb4;
				border-right: 2px solid #cfbcb4;
				border-radius: 10px 10px 0 0;
			}

			&:not(.active) a:hover {
				color: #999999;
			}

			a {
				display: block;
				padding: 20px 40px;
				color: #564b44;
				text-transform: uppercase;
				font: 500 1.125rem/1 'Fira Sans';
				text-decoration: none;
			}
		}
	}
	
	.tab {
		&-content {
			position: relative;
			padding: 30px;
			border-left: 2px solid #cfbcb4;
			border-right: 2px solid #cfbcb4;
			border-bottom: 2px solid #cfbcb4;
		}

		&-wrapper {
			display: flex;
			margin-bottom: 30px;
		}

		&-gallery {
			max-height: 450px;
			flex-shrink: 0;
			width: 555px;

			.gallery {
				width: 100%;

				img {
					max-height: 450px;
					margin: 0 auto;
					max-width: 600px;
				}

				.owl-controls {
					position: absolute;
					top: 20px;
					left: calc(100% + 50px);
				}
			}
		}

		&-description {
			margin-left: 50px;
			padding-top: 120px;

			p {
				margin-bottom: 25px;
				font: 500 1.125rem 'Fira Sans';
			}

			ul {
				list-style: none;
				font-size: 1.125rem;
			}

			li {
				position: relative;
				margin-bottom: 12px;
				padding-left: 40px;

				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: '';
					width: 25px;
					height: 25px;
					background-color: #ffd674;
					border-radius: 100%;
				}

				&::after {
					position: absolute;
					top: 7px;
					left: 5px;
					content: '';
					background-image: url('../img/anyplace_sprite.png');
					@include icon(14px, 11px, -5px, -5px);
				}
			}
		}
	}

	.bottom-part {
		.interior {
			margin: 80px 0 0 80px;
			font: 700 1.875rem/1.2 'Fira Sans';
		}

		.details {
			margin: 10px 0 40px 80px;
			display: flex;
			align-items: center;

			.icon-details {
				flex-shrink: 0;
				width: 50px;
				height: 50px;
				background: url('../img/closet_icon.png');
			}

			p {
				margin-left: 15px;
				font-weight: 300;
				line-height: 1.2;
			}
		}

		.cards {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		.card {
			position: relative;
			overflow: hidden;
			width: 300px;
			height: 300px;
			text-align: center;

			&-info {
				position: absolute;
				left: 0;
				bottom: -110px;
				width: 100%;
				background: rgba(255, 255, 255, 0.85);
				transition: all 0.5s;

				.visible-part {
					padding: 12px;
					font: 500 0.93rem 'Fira Sans';
				}

				.hidden-part {
					margin: 5px 15px 20px;
					padding: 15px 10px;
					font: 300 0.875rem 'Fira Sans';
					color: #4a4653;
					border: 2px solid #96937f;
				}
			}

			&:nth-of-type(3) .card-info {
				bottom: -127px;
			}

			&:hover .card-info{
				bottom: 0;
			}
		}
	}
}



/*______________________________________________________
_________________________ map __________________________
_____________________ 12th screen  ___________________*/

.map {
	position: relative;
	padding: 0;
	height: 850px;

	.container {
		position: relative;
	}

	#map {
		width: 100%;
		height: 100%;
	}
	
	.addresses {
		display: flex;

		&-back {
			position: absolute;
			max-width: 100%;
			z-index: 10;
			top: -75px;
			left: 0;
			background: #ffecba;
			padding: 73px 25px 25px;
		}

		&-wrapper {
			position: relative;
			margin: 0 auto;
			padding: 10px;
			width: 100%;
			border: 2px solid #bfae82;

			&::before {
				position: absolute;
				top: -54px;
				right: 25px;
				content: '';
				background: url('../img/map_addresses-top.png');
				width: 247px;
				height: 113px;
			}
		}

		&-title {
			margin: 10px 0 20px;
			color: #5a4a42;
			font: 700 1.25rem/1.2 'Fira Sans';
			text-align: center;
		}

		&-item {
			padding: 0 3px;
			width: 14.28%;
			font-size: 12px;
			border-right: 2px solid #b9a87e;
			text-align: center;

			&:last-of-type {
				border: none;
			}

			&-location {
				min-height: 96px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-phones {
				margin: 10px 0;
				font-weight: 700;

				a {
					display: block;
				}
			}

			&-mail {
				margin-top: 10px;
			}

			&-timetable {
				margin-top: 12px;
			}
		}
	}

	.carousel-addresses-item {
		padding: 0 3px;
		width: 100%;
		font-size: 12px;
		border-right: 2px solid #b9a87e;
		text-align: center;

		&:last-of-type {
			border: none;
		}

		.addresses-item {
			&-location {
				min-height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-phones {
				margin: 10px 0;
				font-weight: 700;

				a {
					display: block;
				}
			}

			&-mail {
				margin-top: 10px;
			}

			&-timetable {
				margin-top: 12px;
			}
		}
	}

	.owl-controls .owl-nav {
		margin: 30px 0 10px;
		justify-content: center;
	}
}


/*______________________________________________________
________________________ footer ______________________*/

footer {
	padding: 30px 0;
	color: #373736;
	font-size: 0.875rem;

	.flex-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		flex-shrink: 0;

		&-text {
			margin: -10px 0 0 75px;
			line-height: 1;
		}
	}

	.phone {
		position: relative;
		font: 700 1.81rem/1.2 'Fira Sans';
		color: #E61F31;

		&::before {
			position: absolute;
			top: 13px;
			left: -22px;
			content: '';
			width: 14px;
			height: 15px;
			background-image: url('../img/anyplace_sprite.png');
			@include icon(14px, 15px, -181px, -5px);
		}

		&-wrapper {
			text-align: center;
			flex-shrink: 0;
		}
	}

	.call-back {
		display: inline-block;
		line-height: 1;
		border-bottom: 1px dashed #373736;
	}

	.developer {
		p {
			margin-bottom: 10px;
		}

		.diving-marketing {
			display: inline-block;
			width: 121px;
			height: 39px;
			background-image: url('../img/footer_logo-dm.png');
			background-position: -5px -5px;
			transition: none;

			&:hover {
				background-position-x: -136px;
			}
		}
	}

	.policy {
		margin-top: 30px;
		text-align: center;

		a {
			display: inline-block;
			margin: 0 10px;
			line-height: 1;
			border-bottom: 1px solid  #373736;

			&:hover {
				border-bottom-style: dashed;
			}
		}

	}
}





#modal-stock-miniature {
	position: fixed;
	z-index: 1000;
	display: none;
	bottom: 100px;
	right: 3%;
	border-radius: 50px;
	background: #424a4d;

	#modal-stock-miniature-btn {
		text-transform: uppercase;
		padding: 19px 150px 14px 50px;
		cursor: pointer;
	}

	p {
		color: #FFBA00;
		text-shadow: 0 -2px 1px #FFFFFF;
		text-transform: uppercase;
		font: 700 2.125rem/0.8 'Fira Sans';
		line-height: 0.8;
	}

	#modal-stock-miniature-minimize {
		position: absolute;
	 	top: -16px;
	 	right: -16px;
	 	color: #6b7072;
	 	font-size: 26px;
	 	font-weight: bold;
	 	cursor: pointer;

		&:hover {
			color: #ff7381;
		}
	}

	&::after {
		position: absolute;
		z-index: -1;
		top: -53px;
   	right: -1px;
		content: '';
		background-image: url('../img/anyplace_sprite.png');
		@include icon(142px, 152px, -29px, -5px);
	}

	&:hover {
		background: #565E62;
	}
}

#modal-stock-miniature-minimized {
	position: fixed;
	z-index: 1000;
	display: none;
	bottom: 100px;
	right: 2%;
	cursor: pointer;
   background-image: url('../img/modal_sprite.png');
	@include icon(84px, 91px, -414px, -5px);
}


/*_____________________________________________________
______________________ modals _______________________*/
@import "_modals";

/*_____________________________________________________
_____________________ plugins _______________________*/
@import "_plugins";

/*_____________________________________________________
______________________ media ________________________*/
@import "_media";