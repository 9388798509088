/*********************************************************/
/*********************** min-width************************/
/*********************************************************/

/*________________________________________________________
__________________ min-width: 768px  ___________________*/

@media (min-width: 768px) {
	
	.catalog {
		padding-bottom: 280px;

		.magazine {
			display: block;
			min-width: 600px;
			width: 650px;
			height: 789px;
			background: url('../img/catalog_magazine.png');

			&-wrapper {
				position: absolute;
				z-index: 0;
				top: 235px;
		 		right: calc(50% - 100px);
				display: block;
				width: 650px;
			}

			&-inside {
				position: absolute;
				right: -300px;
				bottom: 50px;
				color: #a8a7a8;
				font-size: 1.125rem;

				&::before {
					position: absolute;
					top: 55px;
					left: -265px;
					content: '';
					width: 248px;
					height: 43px;
					background: url('../img/catalog_arrow.png');
				}
			}
		}
	}

	.modal-base {
		&.modal-catalog {
			.modal-catalog-img {
				position: absolute;
				top: -40px;
				left: -40px;
				display: block;
				width: 500px;
				height: 604px;
				background: url('../img/catalog_magazine.png');
			}
		}
	}


	.enjoy {
		.fittings {
			&::before {
				position: absolute;
				right: -170px;
				top: -429px;
				content: '';
				width: 621px;
				height: 429px;
				background: url('../img/enjoy_girl.png')
			}
		}
	}

	.faq {
		.questions {
			&::before {
				position: absolute;
				top: -30px;
				right: calc(100% + 100px);
				content: '';
				width: 746px;
				height: 608px;
				background: url('../img/faq_kitchen.png');
			}
		}
	}

	.our-goal {
		background: url('../img/perfect-kitchen_big-kitchen.png') no-repeat center;
		background-size: cover;
	}
}

/*________________________________________________________
__________________ min-width: 1400px  __________________*/

@media (min-width: 1400px) {

	.base {
		.content {
			margin-top: 400px;
		}
	}
}



/*********************************************************/
/*********************** max-width ***********************/
/*********************************************************/


/*________________________________________________________
__________________ max-width: 1110px  __________________*/

@media (max-width: 1120px) {

	.closet {
		.container {
			width: 100%;
		}

		.tab {
			&-gallery {
				width: 50%;
			}
		}
	}

	.testimonials {
		.container {
			width: 100%;
		}

		&-item {
			&-photo {
				width: 50%;
			}
		}
	}

	.our-works {
		.container {
			width: 100%;
		}

		.tab {
			&-gallery {
				width: 50%;
			}
		}
	}
}


/*________________________________________________________
__________________ max-width: 992px  ___________________*/

@media (max-width: 992px) {
	.our-works {
		.tab {
			&-wrapper {
				flex-wrap: wrap;
			}

			&-gallery {
				width: 100%;

				.gallery {
					.owl-controls {
						top: calc(100% + 20px);
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}

		.btn {
			margin: 0;

			&-wrapper {
				text-align: center;
			}
		}

		.catalog {
			.magazine {
				&-inside {
					position: absolute;
					right: -250px;
					bottom: 60px;
					color: #a8a7a8;
					font-size: 1.125rem;
				}
			}
		}
	}

	.our-works {
		.tabs {
			margin-left: 0;
			justify-content: center;
		}
	}

	.enjoy {
		.fittings {
			justify-content: center;

			&-item {
				&:nth-of-type(8) {
					width: 300px;
					
					.fittings-item-info {
						bottom: -149px;
					}

					&:hover {
						.fittings-item-info {
							bottom: 0;
						}
					}
				}
			}
		}
	}

	.factory {
		.video {
			&-wrapper {
				flex-wrap: wrap;
				margin-top: 30px;
				height: auto;

				p {
					text-align: center;
					margin-bottom: 20px;
				}
			}
		}

		.quote {
			&-wrapper {
				margin-top: 50px;
			}
		}

		.advantages {
			&-item {
				min-height: 260px;
			}
		}
	}

	.perfect-kitchen {
		.girl {
			height: auto;

			&-wrapper {
				position: static;
				margin-top: 30px;
				text-align: center;
				width: 100%;

				&::before {
					content: none;
				}
			}
		}

		.steps {
			margin: 30px 0 0 15%;

			&-item {
				&:nth-of-type(2),
				&:nth-of-type(3),
				&:nth-of-type(4),
				&:nth-of-type(5) {
					margin-left: 0;
				}

				&:nth-of-type(6) {
					margin-left: -25px;					
				}
			}
		}
	}

	.testimonials {
		.owl-controls {
			right: calc(50% - 68px);
		}

		&-item {
			height: auto;
			flex-direction: column;		

			&-photo {
				margin: 0 auto;
				max-height: 500px;
				width: auto;
				max-width: 500px;
			}	

			&-info {
				padding: 20px;
			}

			&-title {
				text-align: center;
			}

			&-author {
				margin-top: 0;
				align-items: center;
				justify-content: center;

				&-name {
					margin-bottom: 0;
				}
			}
		}
	}

	.faq {
		.questions {
			margin-left: 270px;
		}
	}

	.design-variants {
		.flex-container {
			flex-direction: column;
		}

		.samples {
			margin-bottom: 40px;
		}
	}

	.closet {
		.tabs {
			justify-content: center;
			padding-left: 0;

			li {
				a {
					padding: 20px 35px;
				}
			}
		}

		.tab {
			&-wrapper {
				flex-wrap: wrap;
			}

			&-gallery {
				width: 100%;

				.gallery {
					.owl-controls {
						top: calc(100% + 20px);
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}

		.btn {
			left: 50%;
			transform: translateX(-50%);
		}

		.catalog {
			.magazine {
				&-inside {
					position: absolute;
					right: -250px;
					bottom: 60px;
					color: #a8a7a8;
					font-size: 1.125rem;
				}
			}
		}

		.bottom-part {
			.cards {
				justify-content: center;
			}
		}
	}

	footer {
		.flex-container {
			flex-wrap: wrap;
		}

		.logo {
			width: 50%;
		}

		.phone-wrapper {
			width: 50%;
			text-align: right;
		}

		.developer {
			margin-top: 30px;
			width: 100%;
			text-align: center;
		}
	}

	.modal-base {
		max-width: 435px;

		&.modal-calculate-kitchen {
			max-width: 435px;
		}

		.content {
			display: none;
		}

		&.modal-stock {
			margin-top: 100px;

			.bottom-part {
				margin-top: 50px;
			}

			.form {
				flex-direction: column;

				.input-wrapper {
					margin-bottom: 15px;
				}

				input[type="submit"] {
					margin-top: 10px;
					padding: 15px;
					height: auto;
				}
			}
		}
	}
}


/*________________________________________________________
__________________ max-width: 767px  ___________________*/

@media (max-width: 767px) {
	header {
		.delivery {
			display: none;
		}
	}

	section {
		padding: 50px 0;
	}

	.section-header {
		font-size: 2.5rem;
		text-align: center;

		.subheader {
			font-size: 1.8rem;
		}
	}

	.base {
		background-position-x: 65%;
		

		.content {
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.order {
			&-kithen {
				text-align: center;
				text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF;
			}

			&-economy {
				margin-top: 20px;
				text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF;

			}

			&-guarantee {
				color: #000000;
				text-shadow: 0 0 20px #FFFFFF, 2px 2px 20px #FFFFFF, -2px -2px 20px #FFFFFF, -2px 2px 20px #FFFFFF, 2px -2px 20px #FFFFFF;
			}
		}

		.btn-wrapper {
			margin-top: 30px;
			justify-content: center;
		}
	}


	.our-works {
		.section-header {
			margin-left: 0
		}
	}

	.catalog {
		.form {
			&-flex-container {
				justify-content: center;
			}
		}
	}

	.our-goal {
		margin-bottom: 0;
		padding: 50px 0;
		height: auto;
		text-align: center;

		p {
			text-align: center;
		}
	}

	.enjoy {
		padding-top: 50px;
	}

	.factory {
		.quote {
			margin-top: 20px;

			&-wrapper {
				flex-direction: column;
			}

			&-title {
				font-size: 1.6rem;
				text-align: center;
			}

			&-author {
				justify-content: center;
			}
		}

		.advantages {
			flex-direction: column;
			align-items: center;

			&-item {
				margin-bottom: 80px;
				padding: 10px;
				width: 100%;
				max-width: 400px;
				min-height: auto;

				&-title {
					margin: 10px 0;
					font-size: 1.11rem;
				}
			}
		}
	}

	.testimonials {
		&-item {
			/*&-photo {
				max-height: 500px;
				max-width: 500px;
			}	*/
		}

		&-carousel {
			margin-top: 30px;			
		}
	}

	.faq {
		.questions {
			min-height: auto;
			margin: 50px 0 0 70px;
		}

		.no-answer {
			font-size: 2.5rem;
		}

		.ask-now {
			font-size: 1.8rem;
		}
	}

	.design-variants {
		.additional {
			flex-wrap: wrap;

			&-title {
				text-align: center;
			}

			&-item {
				padding-bottom: 20px;
				width: 49%;
				min-height: auto;
			}
		}

		.samples {
			&-title {
				text-align: center;
			}
		}
	}


	.closet {
		.tabs {
			justify-content: center;
			margin-left: 0;

			li {
				a {
					padding: 15px 15px;
					font-size: 1rem;
				}
			}
		}

		.bottom-part {
			.interior {
				margin-left: 0;
				text-align: center;
				font-size: 1.5rem;
			}

			.details {
				margin-left: 0;
				justify-content: center;
			}
		}
	}

	.modal-base {
		&.modal-stock {
			.modal-stock-header {
				&::after {
					left: 50%;
					transform: translateX(-50%);
				}
			}

			.main-part {
				margin: 20px 20px 0;
				padding: 400px 0 30px;
				text-align: center;

				&::before {
					top: -10px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}


/*________________________________________________________
__________________ max-width: 600px  ___________________*/

@media (max-width: 600px) {

}


/*________________________________________________________
__________________ max-width: 479px  ___________________*/

@media (max-width: 479px) {

	html {
		font-size: 14px;
	}

	.form {
		.userfile {
			
		}
	}

	.mfp-close-btn-in .mfp-close {
		top: -35px;
		right: 0px;
	}

	.section-header {
		font-size: 2rem;

		.subheader {
			font-size: 1.5rem;
		}
	}

	.form {
		padding: 30px 15px 0;

		&-wrapper {
			padding: 60px 15px 25px;
		}

		input[type="submit"] {
			width: 100%;
			font-size: 1.11rem;
		}

		.userfile {
			&::after {
				padding: 20px 0 0 25px;
				line-height: 1.2;
			}
		}

		.tooltip-text {
			top: -48px;
		}
	}

	header {
		flex-wrap: wrap;
		
		.logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			&-text {
				width: 50%;
				margin: 5px 0 0 5px;
				text-align: right;
			}

			a {
				width: 50%;
			}
		}

		.phone {
			font-size: 1.5rem;
			margin-left: 10px;

			&::before {
				top: 9px;
			}

			&-wrapper {
				margin-top: 10px;
				width: 100%;
				text-align: center;

				p:first-of-type {
					display: none;
				}

				p {
					margin-top: -5px;
				}
			}
		}
	}

	.base {
		.btn {
			width: 100%;
			max-width: 382px;

			&::after {
				bottom: -111px;
				right: -44px;
			}
		}
	}

	.our-works {
		.tabs {
			li {
				a {
					padding: 12px;
				}
			}
		}

		.tab {
			&-content {
				padding: 20px;
			}

			&-description {
				margin-left: 0;

				ul {
					font-size: 1.11rem;
				}
			}
		}

		.btn {
			max-width: 260px;
		}
	}

	.catalog {
		.form-flex-container {
			margin-top: 40px;
		}
	}
	
	.enjoy {
		.fittings{
			&-item {
				&:nth-of-type(2),
	   		&:nth-of-type(3) {
					.fittings-item-info {
	   				bottom: -120px;
	   			}

	   			&:hover {
						.fittings-item-info {
							bottom: 0;
						}
					}
	   		}

	   		&:nth-of-type(4),
	   		&:nth-of-type(5) {
					.fittings-item-info {
	   				bottom: -105px;
	   			}

	   			&:hover {
						.fittings-item-info {
							bottom: 0;
						}
					}
	   		}

				&:nth-of-type(8) {
					.fittings-item-info {
	   				bottom: -127px;
	   			}

	   			&:hover {
						.fittings-item-info {
							bottom: 0;
						}
					}
	   		}
	   	}
	   }
	}

	.factory {
		.video {
			width: 350px;
			height: 158px;

			&-wrapper {
				p {
					font-size: 1rem;
				}
			}
		}
	}

	.testimonials {
		.owl-carousel {
			.owl-item {
				.testimonials-item-author {
					img {
						width: 100px;
						height: 100px;
					}
				}
			}
		}

		&-item {
			&-author {
				&-name {
					margin-left: 10px;
					line-height: 1.2;					
				}
			}
		}
	}

	.perfect-kitchen {
		.steps {
			margin-left: 30px;
		}
	}

	.faq {
		.questions {
			margin-left: 58px;

			&-item {
				&::before {
					left: -50px;
				}
			}
		}
	}

	.design-variants {
		.tab-content {
			padding: 15px;
		}



		.tabs {
			li {
				a {
					font-size: 1rem;
				}
			}
		}

		.additional {
			justify-content: center;

			&-item {
				max-width: 400px;
				width: 100%;
			}
		}

		.form {
			.bottom-text {
				width: 220px;
			}
		}
	}

	.closet {
		.btn {
			width: calc(100% - 20px);
			padding: 25px 5px 20px;
		}

		.tab {
			&-description {
				margin-left: 0;
			}

			&-content {
				padding: 20px;
			}
		}

		.tabs {
			li {
				width: 25%;
				text-align: center;

				a {
					padding: 15px 7px;
					font-size: 0.75rem;
				}
			}
		}

		.bottom-part {
			.card {
				&-info {
					bottom: -100px;
				}

				&:nth-of-type(3) {
					.card-info {
						bottom: -115px;
					}

					&:hover {
						.card-info {
							bottom: 0;
						}
					}
				}
			}
		}
	}

	footer {
		.flex-container {
			flex-wrap: wrap;
		}
		
		.logo {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			&-text {
				width: 50%;
				margin: 5px 0 0 5px;
				text-align: right;
			}

			a {
				width: 50%;
			}
		}

		.phone {
			font-size: 1.5rem;
			margin-left: 10px;

			&::before {
				top: 9px;
			}

			&-wrapper {
				margin-top: 10px;
				width: 100%;
				text-align: center;

				p:first-of-type {
					display: none;
				}

				p {
					margin-top: -5px;
				}
			}
		}
	}

	.modal-base {
		&.modal-thanks {
			padding: 65px 15px 40px;
		}

		&.modal-stock {
			.modal-stock-header {
				&::after {
					border-width: 20px 180px 0 180px;
				}

				.your-gift {
					margin-top: 30px;

					&::after {
						content: none;
					}
				}
			}
		}
	}
}
