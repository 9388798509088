.mfp-close-btn-in .mfp-close {
	width: 40px;
	height: 40px;
	top: -20px;
	right: -20px;
	color: #5a4a42;
	background-color: #FFFFFF;
	border-radius: 50%;
	font-size: 2rem;
	line-height: 1;
	opacity: 1;

	&:hover {
		background: #ffecba;
	}
}

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;

	-webkit-transition: all 0.2s ease-in-out; 
	-moz-transition: all 0.2s ease-in-out; 
	-o-transition: all 0.2s ease-in-out; 
	transition: all 0.2s ease-in-out; 



	-webkit-transform: scale(0.8); 
	-moz-transform: scale(0.8); 
	-ms-transform: scale(0.8); 
	-o-transform: scale(0.8); 
	transform: scale(0.8); 
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;

	-webkit-transform: scale(1); 
	-moz-transform: scale(1); 
	-ms-transform: scale(1); 
	-o-transform: scale(1); 
	transform: scale(1); 
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8); 
	-moz-transform: scale(0.8); 
	-ms-transform: scale(0.8); 
	-o-transform: scale(0.8); 
	transform: scale(0.8); 

	opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out; 
	-moz-transition: opacity 0.3s ease-out; 
	-o-transition: opacity 0.3s ease-out; 
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}



.owl-controls {
	.owl-nav {
		display: flex;
	}

	.arrow-left,
	.arrow-right {
		position: relative;
		width: 56px;
		height: 56px;
		border: 2px solid #c3c2c2;
		border-radius: 50%;

		&::before {
			position: absolute;
			top: 18px;
			content: '';
			width: 15px;
			height: 15px;
		}

		&:hover {
			border-color: #f4d579;
			background-color: #f4d579;

			&::before {
				border-color: #cc5d3f;
			}
		}
	}

	.arrow-left {
		&::before {
			left: 22px;
			border-left: 4px solid #c3c2c2;
			border-bottom: 4px solid #c3c2c2;
			transform: rotate(45deg);
		}
	}

	.arrow-right {
		margin-left: 25px;

		&::before {
			right: 22px;
			border-right: 4px solid #c3c2c2;
			border-bottom: 4px solid #c3c2c2;
			transform: rotate(-45deg);
		}
	}
}

.select2-container {
	width: 100% !important;
	height: 44px;
	border: 2px solid #a3a0a7;
	border-radius: 5px;
		/*&:focus {
			border-bottom-color: #000000;
		}*/
}
/*
.select2-selection {
	padding: 6px;
}

.select2-container .select2-selection--single {
	height: 40px;
	border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 5px;
	right: 5px;
	width: 30px;
	height: 30px;
	background: #FFBA00;
	border-radius: 50%;

	&::before {
		position: absolute;
		top: 7px;
		left: 9px;
		content: '';
		width: 12px;
		height: 12px;
		border-left: 2px solid #cc5d3f;
		border-bottom: 2px solid #cc5d3f;
		transform: rotate(-45deg);
	}

	b {
		display: none;
	}
}

.select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow {
	&::before {
		top: 12px;
		transform: rotate(135deg);
	}
}

.select2-container--default .select2-results>.select2-results__options {
	min-height: 210px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: lighten(#cc5d3f, 10%);
}*/

.chosen-container {
	width: 100% !important;
	height: 44px;

	border: 2px solid #a3a0a7;
	border-radius: 5px;
	outline: none;

	&:focus {
		border-color: #000000;
	}
}


.chosen-container-single .chosen-single {
	height: 100%;
	background: none;
	border: none;
}

.chosen-container-single .chosen-single span {
	height: 100%;
	padding: 10px;
	font: 300 1rem 'Fira Sans';
}

.chosen-container-single .chosen-single div {
	width: 36px;
	padding-top: 5px;
}



.chosen-container-single .chosen-single div b {
	position: relative;
	width: 30px;
	height: 30px;
	background: #FFBA00;
	border-radius: 50%;

	&::before {
		position: absolute;
		top: 7px;
		left: 9px;
		content: '';
		width: 12px;
		height: 12px;
		border-left: 2px solid #cc5d3f;
		border-bottom: 2px solid #cc5d3f;
		transform: rotate(-45deg);
	}
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
	&::before {
		top: 12px;
		transform: rotate(135deg);
	}
}

.chosen-container .chosen-results {
	margin: 0;
	padding: 0;
}

.chosen-container .chosen-results li {
	padding: 10px;
	font: 300 1rem 'Fira Sans';
}